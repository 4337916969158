import Vue from "vue";
import Store from "@/store";

export default {
  path: "/conferences",
  name: "app",
  component: () => import("../views/AppView.vue"),
  redirect: { name: "app.conferences" },
  meta: { title: Vue.prototype.$gettext("App") },
  children: [
    {
      path: "",
      name: "app.conferences",
      component: () => import("../views/app/ConferencesView.vue"),
      meta: { title: Vue.prototype.$gettext("Conferences") },
    },
    {
      path: ":conferenceId",
      name: "app.conference",
      component: () => import("../views/app/ConferenceView.vue"),
      meta: { title: Vue.prototype.$gettext("Conference") },
      redirect: { name: "app.conference.lobby" },
      beforeRouteEnter: (to, from, next) => {
        // Check if the user has permission to access the selected item
        const checkId = to.params.conferenceId;
        Store.dispatch("conference/conferenceExists", checkId)
          .then(() => {
            next();
          })
          .catch(() => {
            // redirect
            next("/404");
          });
      },
      children: [
        {
          path: "lobby",
          name: "app.conference.lobby",
          component: () => import("../views/app/conference/ConferenceLobbyView.vue"),
          meta: { title: Vue.prototype.$gettext("Lobby"), conferenceChatAllowed: true },
        },
        {
          path: "stages",
          name: "app.conference.stages",
          component: () => import("../views/app/conference/ConferenceStagesView.vue"),
          meta: { title: Vue.prototype.$gettext("Stages"), conferenceChatAllowed: true, allowLiveAttendee: true },
        },
        {
          path: "stages/:stageId/live",
          name: "app.conference.stage.live",
          component: () => import("../views/app/conference/live/ConferenceStageLiveView.vue"),
          meta: { title: Vue.prototype.$gettext("Live Stage"), focusMode: true, allowLiveAttendee: true },
        },
        {
          path: "stages/:stageId",
          name: "app.conference.stage",
          component: () => import("../views/app/conference/ConferenceStageView.vue"),
          meta: { title: Vue.prototype.$gettext("Stage") },
          // beforeEnter: (to, from, next) => {
          //   // Check if the user has permission to access the selected item
          //   const conferenceId = to.params.conferenceId;
          //   const checkId = to.params.stageId;
          //   Store.dispatch("conference/fetchConferenceStage", { conferenceId: conferenceId, stageId: checkId })
          //     .then(() => {
          //       next();
          //     })
          //     .catch(() => {
          //       // redirect
          //       next("/404");
          //     });
          // },
        },
        {
          path: "showrooms",
          name: "app.conference.showrooms",
          component: () => import("../views/app/conference/ConferenceShowroomsView.vue"),
          meta: { title: Vue.prototype.$gettext("Showrooms"), conferenceChatAllowed: true },
        },
        {
          path: "showrooms/:showroomId",
          name: "app.conference.showroom",
          component: () => import("../views/app/conference/ConferenceShowroomView.vue"),
          meta: { title: Vue.prototype.$gettext("Showroom") },
          // beforeEnter: (to, from, next) => {
          //   // Check if the user has permission to access the selected item
          //   const conferenceId = to.params.conferenceId;
          //   const checkId = to.params.showroomId;
          //   Store.dispatch("conference/fetchConferenceShowroom", { conferenceId: conferenceId, showroomId: checkId })
          //     .then(() => {
          //       next();
          //     })
          //     .catch(() => {
          //       // redirect
          //       next("/404");
          //     });
          // },
        },
        {
          path: "sessions",
          name: "app.conference.sessions",
          component: () => import("../views/app/conference/ConferenceSessionsView.vue"),
          meta: { title: Vue.prototype.$gettext("Sessions"), conferenceChatAllowed: true },
        },
        {
          path: "sessions/:sessionId",
          name: "app.conference.session",
          component: () => import("../views/app/conference/ConferenceSessionView.vue"),
          meta: { title: Vue.prototype.$gettext("Session") },
          // beforeEnter: (to, from, next) => {
          //   // Check if the user has permission to access the selected item
          //   const conferenceId = to.params.conferenceId;
          //   const checkId = to.params.sessionId;
          //   Store.dispatch("conference/fetchConferenceSession", { conferenceId: conferenceId, sessionId: checkId })
          //     .then(() => {
          //       next();
          //     })
          //     .catch(() => {
          //       // redirect
          //       next("/404");
          //     });
          // },
        },
        {
          path: "meetings",
          name: "app.conference.meetings",
          component: () => import("../views/app/conference/ConferenceMeetingsView.vue"),
          meta: { title: Vue.prototype.$gettext("Meetings"), conferenceChatAllowed: true },
        },
        {
          path: "meeting/new",
          name: "app.conference.meeting.new",
          component: () => import("../views/app/conference/ConferenceNewMeetingView.vue"),
          meta: { title: Vue.prototype.$gettext("New Meeting") },
        },
        {
          path: "meeting/:meetingId",
          name: "app.conference.meeting",
          component: () => import("../views/app/conference/ConferenceMeetingView.vue"),
          meta: { title: Vue.prototype.$gettext("Meeting") },
          // beforeEnter: (to, from, next) => {
          //   // Check if the user has permission to access the selected item
          //   // const conferenceId = to.params.conferenceId;
          //   // const checkId = to.params.meetingId;
          //   // Store.dispatch("meetings/fetchMeeting", { conferenceId: conferenceId, meetingId: checkId })
          //   //   .then(() => {
          //   //     next();
          //   //   })
          //   //   .catch(() => {
          //   //     // redirect
          //   //     next("/404");
          //   //   });
          // },
        },
        {
          path: "attendees",
          name: "app.conference.attendees",
          component: () => import("../views/app/conference/ConferenceAttendeesView.vue"),
          meta: { title: Vue.prototype.$gettext("Attendees"), conferenceChatAllowed: true },
        },
        {
          path: "attendee/:attendeeId",
          name: "app.conference.attendee",
          component: () => import("../views/app/conference/ConferenceAttendeeView.vue"),
          meta: { title: Vue.prototype.$gettext("Attendee") },
          // beforeEnter: (to, from, next) => {
          //   // Check if the user has permission to access the selected item
          //   const conferenceId = to.params.conferenceId;
          //   const checkId = to.params.attendeeId;
          //   Store.dispatch("conference/fetchConferenceAttendee", { conferenceId: conferenceId, attendeeId: checkId })
          //     .then(() => {
          //       next();
          //     })
          //     .catch(() => {
          //       // redirect
          //       next("/404");
          //     });
          // },
        },
      ],
    },
    // {
    //   path: ':conferenceId/showrooms/:showroomId',
    //   name: 'app.conference.showroom',
    //   component: () => import('../views/app/ConferenceShowroomView.vue'),
    //   meta: { title: Vue.prototype.$gettext("Showroom") }
    // },
    // {
    //   path: ':conferenceId/stage/:stageId',
    //   name: 'app.conference.stage',
    //   component: () => import('../views/app/ConferenceStageView.vue'),
    //   meta: { title: Vue.prototype.$gettext("Stage") }
    // },
    // {
    //   path: ':conferenceId/stage/:stageId/live',
    //   name: 'app.conference.stage.live',
    //   component: () => import('../views/app/ConferenceStageLiveView.vue'),
    //   meta: { title: Vue.prototype.$gettext("Stage live") }
    // },
    // {
    //   path: ':conferenceId/stage/:stageId/video/:recordingId',
    //   name: 'app.conference.stage.video',
    //   component: () => import('../views/app/ConferenceStageVideoView.vue'),
    //   meta: { title: Vue.prototype.$gettext("Stage video") }
    // }
  ],
};
