import CommonService from "@/services/common.service";
import TokenService from "@/services/token.service";
import Store from "@/store";

const handleLoggedInUser = (to, from, next) => {
  if (to.meta.requiresManager && to.params.conferenceId && !Store.getters["auth/isConferenceManager"](to.params.conferenceId)) {
    next({ name: "error", params: { error: "You are not authorized to access this page." } });
  } else if (Store.getters["auth/isLiveAttendee"] && !to.meta.allowLiveAttendee) {
    // If the user is live attendee limit him to routes that are allow to liveAttendees
    if (to.params.conferenceId) {
      next({ name: "app.conference.stages", params: { conferenceId: to.params.conferenceId } });
    } else {
      next({ name: "error", params: { error: "You are not authorized to access this page." } });
    }
  } else {
    // Confinute if user is logged in and has user
    next();
  }
};

export default async (to, from, next) => {
  const loggedIn = TokenService.hasToken();

  // Set title
  if (to.meta && to.meta.title) {
    let newTitle = CommonService.routerTitles()[to.name] || to.meta.title;
    // if (Store.state.conference.stage) newTitle += " • " + Store.state.conference.stage.name;
    // if (Store.state.conference.showroom) newTitle += " • " + Store.state.conference.showroom.name;
    if (Store.state.conference.conference) newTitle += " • " + Store.state.conference.conference.name;
    newTitle += " • " + process.env.VUE_APP_TITLE;
    document.title = newTitle;
  } else {
    document.title = process.env.VUE_APP_TITLE;
  }

  if (to.meta.public || to.meta.name == "error") {
    // Going to public route, move along
    next();
  } else if (loggedIn && !Store.state.auth.user) {
    try {
      // Fetch user profile
      await Store.dispatch("auth/getUser");
      Store.dispatch("general/setUserClock");

      handleLoggedInUser(to, from, next);
      // // check if route requires manager permission
      // if (to.meta.requiresManager && to.params.conferenceId && !Store.getters["auth/isConferenceManager"](to.params.conferenceId)) {
      //   next({ name: "error", params: { error: "You are not authorized to access this page." } });
      // } else {
      //   next();
      // }
    } catch (error) {
      // Handle unknown error
      console.error("Before each:", error);
      if (error && error.response && error.response.code != 401) {
        next({
          name: "error",
          params: {
            error,
          },
        });
      }
    }
  } else if (loggedIn && Store.state.auth.user) {
    // check if route requires manager permission
    handleLoggedInUser(to, from, next);
  } else {
    // User not logged in
    next();
    Store.dispatch("auth/logout");
  }
};
