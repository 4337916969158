<template>
  <div class="confiva-icon" :style="iconStyle" @click.stop="$emit('click', $event)">
    <svg><use :xlink:href="icon"></use></svg>
  </div>
</template>

<script>
export default {
  computed: {
    iconStyle() {
      return {
        "--icon-size": this.size + "px",
        "--icon-scale-x": this.flipX ? "-1" : "1",
        "--icon-scale-y": this.flipY ? "-1" : "1",
        "--icon-fill": this.fill,
        background: this.background,
        padding: this.padding + "px",
        cursor: this.clickable ? "pointer" : "default",
        "border-radius": this.rounded ? "50%" : "0",
      };
    },
  },

  props: {
    icon: String,
    flipX: Boolean,
    flipY: Boolean,
    rounded: Boolean,
    clickable: Boolean,
    padding: {
      type: String,
      default: "0",
    },
    fill: {
      type: String,
      default: "black",
    },
    background: {
      type: String,
      default: "initial",
    },
    size: {
      type: Number,
      default: 20,
    },
  },
};
</script>

<style scoped lang="scss">
.confiva-icon {
  width: var(--icon-size);
  height: var(--icon-size);
  min-width: var(--icon-size);
  min-height: var(--icon-size);
  transform: scaleX(var(--icon-scale-x)) scaleY(var(--icon-scale-y));

  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    fill: var(--icon-fill);
    width: 100%;
    height: 100%;
  }
}
</style>
