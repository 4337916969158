import StreamModel from "@/models/stream.model";
import GroupModel from "@/models/group.model";
import RecordingModel from "@/models/recording.model";
import CommonService from "@/services/common.service";

enum StageStatus {
  LIVE = "live",
  STARTING = "starting",
  ENDED = "ended",
  NEXT = "next",
}

class QAModel {
  id: number;
  moderators: number[];
  // is_moderator: boolean;
  allow_anonymous: boolean;
  private: boolean;
  channel_name: string;
  constructor(data: Record<string, any>) {
    this.id = data?.id;
    this.moderators = data?.moderators;
    // this.is_moderator = data?.is_moderator;
    this.allow_anonymous = data?.allow_anonymous;
    this.private = data?.private;
    this.channel_name = data?.channel_name;
  }
}

export default class StageModel {
  id: number;
  name: string;
  description: string;
  cover_photo?: string;
  thumbnail_photo?: string;
  chat_channel: string;
  long_description: string;
  current_viewer_count: number;
  enable_viewer_counter: boolean;
  viewer_counter_visible: boolean;
  end_date: Date;
  start_date: Date;
  stream: StreamModel;
  groups: GroupModel[];
  recordings: RecordingModel[];
  enable_chat: boolean;
  enable_polls: boolean;
  enable_qa: boolean;
  qa: QAModel | any;
  qr_code: number;

  status?: StageStatus;

  // enable_mux_studio: boolean;
  // mux_studio_host_group?: string;
  // mux_studio_guest_groups: number[];
  // mux_studio_background_photo: string;
  // mux_studio_overlay_photo: string;

  enable_virtual_studio: boolean;

  zoom_meeting_id: string;
  zoom_meeting_password: string;

  wb_meeting_id: string;
  wb_meeting_url: string;

  virtual_studio_host_group: string;
  virtual_studio_guest_groups: number[];

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.name = data.name || "No title!"; // don't translate here
    this.description = data.description;
    this.cover_photo = data.cover_photo;
    this.thumbnail_photo = data.thumbnail_photo;
    this.chat_channel = data.chat_channel;
    this.long_description = data.long_description;
    this.current_viewer_count = data.current_viewer_count;
    this.enable_viewer_counter = data.enable_viewer_counter;
    this.viewer_counter_visible = data.viewer_counter_visible;
    this.end_date = new Date(data.end_date);
    this.start_date = new Date(data.start_date);
    this.stream = new StreamModel(data.stream);
    this.groups = [];
    this.recordings = [];
    this.enable_chat = data.enable_chat;
    this.enable_polls = data.enable_polls;
    this.enable_qa = data.enable_qa;
    this.qr_code = data.qr_code;
    if (data.qa) {
      this.qa = new QAModel(data.qa);
    }
    // this.enable_mux_studio = data.enable_mux_studio;
    // this.mux_studio_host_group = data.mux_studio_host_group;
    // this.mux_studio_guest_groups = data.mux_studio_guest_groups;
    // this.mux_studio_background_photo = data.mux_studio_background_photo;
    // this.mux_studio_overlay_photo = data.mux_studio_overlay_photo;

    this.enable_virtual_studio = data.enable_virtual_studio;
    this.zoom_meeting_id = data.zoom_meeting_id;
    this.wb_meeting_id = data.wb_meeting_id;
    this.wb_meeting_url = data.wb_meeting_url;
    this.zoom_meeting_password = data.zoom_meeting_password;
    this.virtual_studio_host_group = data.virtual_studio_host_group;
    this.virtual_studio_guest_groups = data.virtual_studio_guest_groups;

    if (data.groups) {
      data.groups.forEach((g) => {
        const group = new GroupModel(g);
        this.groups.push(group);
      });
    }

    if (data.recordings) {
      data.recordings.forEach((r) => {
        const recording = new RecordingModel(r);
        this.recordings.push(recording);
      });
    }

    this.update();
  }

  update() {
    if (this.stream.is_live) {
      this.status = StageStatus.LIVE;
    } else if (CommonService.isBetween(this.start_date, this.end_date)) {
      this.status = StageStatus.STARTING;
    } else if (CommonService.isBefore(this.start_date)) {
      this.status = StageStatus.NEXT;
    } else if (CommonService.isAfter(this.end_date)) {
      this.status = StageStatus.ENDED;
    }
  }

  updateStream(is_live: boolean, link: URL) {
    this.stream.is_live = is_live;
    this.stream.link = link;
  }
}
