import Vue from "vue";
import SocketService from "@/services/socket.service";
import ConferenceService from "@/services/api/conference.service";
import StageModel from "@/models/stage.model";
import ConferenceModel from "@/models/conference.model";
import CommonService from "@/services/common.service";
import Announcement from "@/models/announcement.model";
import videojs from "video.js";
import Store from "@/store";
import router from "@/router";
import SocketManagementService from "@/services/socket-management.service";

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state = {
  loadingConferences: false,
  conferences: Array<ConferenceModel>(),
  loadingConference: false,
  conference: null,
  loadingStages: false,
  stages: Array<StageModel>(),
  loadingStage: false,
  stage: null,
  loadingShowrooms: false,
  showrooms: [],
  showroom: null,
  loadingSessions: false,
  sessions: [],
  session: null,
  sessionLive: false,
  sessionAttendees: [],
  loadingAnnouncements: false,
  announcements: Array<Announcement>(),
  loadingPolls: false,
  hasNewPoll: false,
  newPoll: null,
  polls: [],
  loadingAttendees: false,
  attendees: [],
  currentAttendee: null,
  activeAttendees: [],
  blacklisted: false,
  showroomGroups: [],

  showroomsScrollPosition: null,
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {
  liveStages() {
    if (state.stages) {
      return state.stages.filter((t) => (t.stream && t.stream.is_live) == true) || state.stages;
    } else {
      return state.stages;
    }
  },

  hasLiveStage() {
    let isLive = false;
    if (state.stages) {
      state.stages.forEach((stage) => {
        if (stage.stream && stage.stream.is_live) {
          isLive = true;
        }
      });
    }
    return isLive;
  },

  hasActiveSession() {
    const now = Store.getters["general/currentDate"];
    let isLive = false;
    if (state.sessions) {
      state.sessions.forEach((session: any) => {
        if (now >= new Date(session.start_date) && now <= new Date(session.end_date)) {
          isLive = true;
          return true;
        }
      });
    }
    return isLive;
  },

  sessionAttendee() {
    if (state.sessionAttendees) {
      if (state.sessionAttendees["participant"]) {
        return state.sessionAttendees["participant"];
      }
    }
    return null;
  },

  sessionAttendees() {
    if (state.sessionAttendees) {
      if (state.sessionAttendees["participants"]) {
        return state.sessionAttendees["participants"];
      }
    }
    return [];
  },

  isSessionLive() {
    if (state.session && state.sessionLive) {
      return true;
    }
    return false;
  },

  showroomGroups() {
    return [
      ...state.showroomGroups,
      {
        id: null,
        name: "",
        order_position: 100000,
      },
    ];
  },

  isBlacklisted() {
    return state.blacklisted;
  },

  currentAttendee() {
    return state.currentAttendee;
  },
};

// ---------------------------------------------------------------- Mutations ----------------------------------------------------------------
const mutations = {
  setConferences(state, conferences: Array<ConferenceModel>) {
    state.conferences = conferences;
  },

  setConference(state, conference: ConferenceModel) {
    state.conference = conference;
    if (state.conference) {
      CommonService.setTitle(conference.name);
      CommonService.loadLanguageFromConference(state.conference);
      Store.dispatch("general/setConferenceDarkMode", conference.dark_mode);
    } else {
      CommonService.setTitle("Confiva");
    }
  },

  setLoadingConferences(state, loading) {
    state.loadingConferences = loading;
  },

  setLoadingConferenceDetails(state, loading) {
    state.loadingStages = loading;
    state.loadingShowrooms = loading;
    state.loadingSessions = loading;
  },

  setShowroomsScrollPosition(state, position) {
    state.showroomsScrollPosition = position;
  },

  setConferenceStages(state, stages: Array<StageModel>) {
    state.stages = stages;
  },

  setConferenceStage(state, stage: StageModel) {
    state.stage = stage;
  },

  setConferenceStagePolls(state, polls) {
    state.polls = polls;
  },

  setBlacklisted(state, blacklisted) {
    state.blacklisted = blacklisted;
  },

  setConferenceStagePoll(state, poll) {
    const pollIndex = state.polls.findIndex((p) => poll.id == p.id);
    if (pollIndex >= 0) {
      Vue.set(state.polls, pollIndex, poll);
    } else {
      state.polls.unshift(poll);
    }
  },

  setHasNewPoll(state, hasNewPoll) {
    state.hasNewPoll = hasNewPoll;
  },

  setNewPoll(state, poll) {
    if (!poll) {
      state.hasNewPoll = false;
    }
    state.newPoll = poll;
  },

  addConferenceStage(state, stage: StageModel) {
    state.stages.unshift(stage);
    state.stages.sort((a, b) => a.order_position - b.order_position);
  },

  removeConferenceStage(state, stageId: number) {
    const stageIndex = state.stages.findIndex((t) => stageId == t.id);
    if (stageIndex >= 0) {
      state.stages.splice(stageIndex, 1);
    }
    if (state.stage && state.stage.id == stageId) {
      state.stage = null;
      router.push({ name: "app.conference.stages" });
    }
  },

  updateConferenceStage(state, stage: StageModel) {
    const stageIndex = state.stages.findIndex((t) => stage.id == t.id);
    if (stageIndex >= 0) {
      Vue.set(state.stages, stageIndex, stage);
      state.stages.sort((a, b) => a.order_position - b.order_position);
    }
    if (state.stage && state.stage.id == stage.id) {
      state.stage = stage;
    }
  },

  addConferenceShowroom(state, showroom) {
    state.showrooms.unshift(showroom);
    state.showrooms.sort((a, b) => a.order_position - b.order_position);
  },

  removeConferenceShowroom(state, showroomId) {
    const showroomIndex = state.showrooms.findIndex((t) => showroomId == t.id);
    if (showroomIndex >= 0) {
      state.showrooms.splice(showroomIndex, 1);
    }
    if (state.showroom && state.showroom.id == showroomId) {
      state.showroom = null;
      router.push({ name: "app.conference.showrooms" });
    }
  },

  updateConferenceShowroom(state, showroom) {
    const showroomIndex = state.showrooms.findIndex((t) => showroom.id == t.id);
    if (showroomIndex >= 0) {
      Vue.set(state.showrooms, showroomIndex, showroom);
      state.showrooms.sort((a, b) => a.order_position - b.order_position);
    }
    if (state.showroom && state.showroom.id == showroom.id) {
      state.showroom = showroom;
    }
  },

  addConferenceSession(state, session) {
    state.sessions.unshift(session);
    state.sessions.sort((a, b) => a.order_position - b.order_position);
  },

  removeConferenceSession(state, sessionId) {
    const sessionIndex = state.sessions.findIndex((t) => sessionId == t.id);
    if (sessionIndex >= 0) {
      state.sessions.splice(sessionIndex, 1);
    }
    if (state.session && state.session.id == sessionId) {
      state.session = null;
      router.push({ name: "app.conference.sessions" });
    }
  },

  updateConferenceSession(state, session) {
    const sessionIndex = state.sessions.findIndex((t) => session.id == t.id);
    if (sessionIndex >= 0) {
      Vue.set(state.sessions, sessionIndex, session);
      state.sessions.sort((a, b) => a.order_position - b.order_position);
    }
    if (state.session && state.session.id == session.id) {
      state.session = session;
    }
  },

  setConferenceShowrooms(state, showrooms) {
    state.showrooms = showrooms;
  },

  setConferenceShowroom(state, showroom) {
    state.showroom = showroom;
  },

  setConferenceShowroomGroups(state, groups) {
    state.showroomGroups = groups;
  },

  setConferenceSessions(state, sessions) {
    state.sessions = sessions;
  },

  setConferenceSession(state, session) {
    state.session = session;
  },

  setConferenceSessionLive(state, live) {
    state.sessionLive = live;
  },

  setConferenceSessionAttendees(state, attendees) {
    state.sessionAttendees = attendees;
  },

  setConferenceAttendees(state, attendees) {
    state.attendees = attendees;
  },

  setConferenceAttendee(state, attendee: any) {
    const itemIndex = state.attendees.findIndex((a) => attendee.id == a.id);
    if (itemIndex >= 0) {
      Vue.set(state.attendees, itemIndex, attendee);
    } else {
      state.attendees.unshift(attendee);
    }
  },

  setConferenceCurrentAttendee(state, attendee: any) {
    state.currentAttendee = attendee;

    const index = state.activeAttendees.findIndex((a) => a == attendee.id);
    if (index < 0) {
      state.activeAttendees.push(attendee.id);
    }
  },

  setConferenceAnnouncements(state, announcements: Array<Announcement>) {
    state.announcements = announcements;
  },

  setConferenceAnnouncement(state, announcement: Announcement) {
    const announcementIndex = state.announcements.findIndex((a) => announcement.id == a.id);
    if (announcementIndex >= 0) {
      Vue.set(state.announcements, announcementIndex, announcement);
    } else {
      state.announcements.unshift(announcement);
    }
  },

  clearPollStore(state) {
    state.loadingPolls = false;
    state.hasNewPoll = false;
    state.newPoll = null;
    state.polls = [];
  },

  cleanStageAndShowroomAndSession(state) {
    state.stage = null;
    state.showroom = null;
    state.session = null;

    // state.loadingAnnouncements = false; // NOTE - uncommenting will remove announcements that will only be loaded when user refreshes the page or when a new announcement is published; this is OK only for `cleanConference(state)`
    // state.announcements = []; // NOTE - uncommenting will remove announcements that will only be loaded when user refreshes the page or when a new announcement is published; this is OK only for `cleanConference(state)`

    state.loadingPolls = false;
    state.hasNewPoll = false;
    state.newPoll = null;
    state.polls = [];
  },

  cleanConference(state) {
    state.loadingConference = false;
    state.conference = null;
    state.loadingStages = false;
    state.stages = [];
    state.stage = null;

    state.loadingPolls = false;
    state.hasNewPoll = false;
    state.newPoll = null;
    state.polls = [];

    state.loadingAnnouncements = false;
    state.announcements = [];

    state.loadingShowrooms = false;
    state.showrooms = [];
    state.showroom = null;

    state.loadingSessions = false;
    state.sessions = [];
    state.session = null;
    state.sessionAttendees = [];

    state.loadingAttendees = false;
    state.attendees = [];
    state.currentAttendee = null;

    state.showroomGroups = [];
    state.showroomsScrollPosition = null;

    Store.commit("meetings/cleanStore");
    Store.commit("notifications/cleanStore");
  },

  cleanStore(state) {
    state.loadingConferences = false;
    state.conferences = [];
    state.loadingConference = false;
    state.conference = null;
    state.loadingStages = false;
    state.stages = [];
    state.stage = null;

    state.loadingPolls = false;
    state.hasNewPoll = false;
    state.newPoll = null;
    state.polls = [];

    state.loadingShowrooms = false;
    state.showrooms = [];
    state.showroom = null;

    state.loadingSessions = false;
    state.sessions = [];
    state.session = null;
    state.sessionAttendees = [];

    state.loadingAttendees = false;
    state.attendees = [];
    state.currentAttendee = null;

    state.showroomGroups = [];
    state.showroomsScrollPosition = null;

    Store.commit("meetings/cleanStore");
    Store.commit("notifications/cleanStore");
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  async fetchConferences(context, filters) {
    if (context.state.loadingConferences) {
      return;
    }
    context.commit("setLoadingConferences", true);
    try {
      const response = await ConferenceService.getAll(filters);
      const conferences: Array<ConferenceModel> = [];
      for (let i = 0; i < response.length; i++) {
        conferences.push(new ConferenceModel(response[i]));
      }
      conferences["_metadata"] = response._metadata;
      context.commit("setConferences", conferences);
      return conferences;
    } catch (error) {
      throw error;
    } finally {
      context.commit("setLoadingConferences", false);
    }
  },

  async conferenceExists(context, conferenceId: number) {
    return await context.dispatch("fetchConference", conferenceId);
  },

  async fetchConferenceDetails(context, conferenceId: number) {
    context.commit("setLoadingConferenceDetails", true);
    const response = await ConferenceService.getDetailsInfo(conferenceId).finally(() => {
      context.commit("setLoadingConferenceDetails", false);
    });
    context.commit("setConferenceStages", response.stages_list);
    context.commit("setConferenceShowrooms", response.showrooms_list);
    context.commit("setConferenceSessions", response.sessions_list);
    context.commit("setConferenceShowroomGroups", response.showrooms_groups_list);
    context.dispatch(
      "updateActiveAttendees",
      response.active_attendees_list.map((attendee) => parseInt(attendee))
    );
    return response;
  },

  async fetchConference(context, conferenceId: number) {
    if (context.state.conference && context.state.conference.id == conferenceId && !context.state.loadingConference) {
      return context.state.conference;
    } else {
      state.loadingConference = true;
      try {
        context.dispatch("stopAllPlayers");
        const response = await ConferenceService.get(conferenceId);
        const conference = new ConferenceModel(response);

        const isLiveAttendee = Store.getters["auth/isLiveAttendee"];

        context.commit("setConference", conference);
        SocketService.setActive(true);
        await context.dispatch("fetchConferenceDetails", conferenceId);
        context.dispatch("fetchConferenceCurrentAttendee", { conferenceId });
        if (!isLiveAttendee) {
          await Store.dispatch("notifications/fetchConferenceUnreadNotifications", { conferenceId });
          context.dispatch("fetchConferenceAnnouncements", { conferenceId });
          Store.dispatch("meetings/fetchMeetings", { conferenceId }).then(() => {
            Store.dispatch("meetings/fetchLastReads", { conferenceId });
            Store.dispatch("meetings/fetchActiveCalls", { conferenceId });
          });
        }
        // const route = router.currentRoute.name;
        // const response = await ConferenceService.get(conferenceId);
        // const conference = new ConferenceModel(response);
        // context.commit("setConference", conference);

        // if (conference.has_stages && route != "app.conference.stages") await context.dispatch("fetchConferenceStages", { conferenceId });
        // await context.dispatch("fetchConferenceAnnouncements", {
        //   conferenceId,
        // });
        // if (conference.has_attendees && route != "app.conference.attendees") await context.dispatch("fetchConferenceAttendees", { conferenceId });
        // if (conference.has_meetings && route != "app.conference.meetings") await Store.dispatch("meetings/fetchMeetings", { conferenceId });
        // if (conference.has_showrooms) await context.dispatch("fetchConferenceShowrooms", { conferenceId });
        // if (conference.has_sessions && route != "app.conference.sessions") await context.dispatch("fetchConferenceSessions", { conferenceId });
        // await context.dispatch("fetchConferenceCurrentAttendee", { conferenceId });

        return conference;
      } catch (error) {
        console.log(error);
        throw error;
      } finally {
        state.loadingConference = false;
      }
    }
  },

  handleConferenceUpdated(context, data) {
    if (context.state.conference && context.state.conference.id == data.id) {
      context.commit("setConference", new ConferenceModel(data));
    }
  },

  async fetchConferenceStages(context, { conferenceId, filters }) {
    state.loadingStages = true;
    try {
      const response = await ConferenceService.getStages(conferenceId, filters);
      const stages: Array<StageModel> = [];
      for (let i = 0; i < response.length; i++) {
        stages.push(new StageModel(response[i]));
      }
      stages["_metadata"] = response._metadata;
      context.commit("setConferenceStages", stages);
      return stages;
    } catch (error) {
      throw error;
    } finally {
      state.loadingStages = false;
    }
  },

  async fetchConferenceStage(context, { conferenceId, stageId }) {
    if (context.state.stage && context.state.stage.id == stageId) {
      return context.state.stage;
    }
    try {
      context.state.loadingStage = true;
      const response = await ConferenceService.getStage(conferenceId, stageId);
      const stage = new StageModel(response);
      context.commit("setConferenceStage", stage);
      context.state.loadingStage = false;
      return stage;
    } catch (error) {
      await router.push({ name: "app.conference.stages" });
      throw error;
    }
  },

  handleStageCreated(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("addConferenceStage", new StageModel(data.data));
    }
  },

  handleStageUpdated(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("updateConferenceStage", new StageModel(data.data));
    }
  },

  handleStageDeleted(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("removeConferenceStage", data.data.id);
    }
  },

  handleStageRecordingUpdated(context, data) {
    if (context.state.stage?.id == data.data.stage) {
      const index = context.state.stage.recordings.findIndex((x) => x.id == data.data.id);
      if (index >= 0) {
        Vue.set(context.state.stage.recordings, index, data.data);
      } else {
        context.state.stage.recordings.unshift(data.data);
      }
    }
  },

  handleStageRecordingDeleted(context, data) {
    if (context.state.stage) {
      const index = context.state.stage.recordings.findIndex((x) => x.id == data.data);
      if (index >= 0) {
        context.state.stage.recordings.splice(index, 1);
      }
    }
  },

  async fetchConferenceShowrooms(context, { conferenceId, filters }) {
    state.loadingShowrooms = true;
    try {
      const response = await ConferenceService.getShowrooms(conferenceId, filters);
      context.commit("setConferenceShowrooms", response);
      return response;
    } catch (error) {
      throw error;
    } finally {
      state.loadingShowrooms = false;
    }
  },

  async fetchConferenceShowroom(context, { conferenceId, showroomId }) {
    if (context.state.showroom && context.state.showroom.id == showroomId) {
      return context.state.showroom;
    }
    try {
      const response = await ConferenceService.getShowroom(conferenceId, showroomId);
      context.commit("setConferenceShowroom", response);
      return response;
    } catch (error) {
      await router.push({ name: "app.conference.showrooms" });
      throw error;
    }
  },

  handleShowroomCreated(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("addConferenceShowroom", data.data);
    }
  },

  handleShowroomUpdated(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("updateConferenceShowroom", data.data);
    }
  },

  handleShowroomDeleted(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("removeConferenceShowroom", data.data.id);
    }
  },

  async fetchConferenceShowroomGroups(context, { conferenceId, filters }) {
    try {
      const response = await ConferenceService.getShowroomGroups(conferenceId, filters);
      context.commit("setConferenceShowroomGroups", response);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchConferenceSessions(context, { conferenceId, filters }) {
    state.loadingSessions = true;
    try {
      const response = await ConferenceService.getSessions(conferenceId, filters);
      context.commit("setConferenceSessions", response);
      return response;
    } catch (error) {
      throw error;
    } finally {
      state.loadingSessions = false;
    }
  },

  async fetchConferenceSession(context, { conferenceId, sessionId }) {
    if (context.state.session && context.state.session.id == sessionId) {
      return context.state.session;
    }
    try {
      const response = await ConferenceService.getSession(conferenceId, sessionId);
      context.commit("setConferenceSession", response);
      return response;
    } catch (error) {
      await router.push({ name: "app.conference.sessions" });
      throw error;
    }
  },

  handleSessionCreated(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("addConferenceSession", data.data);
    }
  },

  handleSessionUpdated(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("updateConferenceSession", data.data);
    }
  },

  handleSessionDeleted(context, data) {
    if (context.state.conference && context.state.conference.id == data.conference_id) {
      context.commit("removeConferenceSession", data.data.id);
    }
  },

  async fetchConferenceSessionAttendees(context, { conferenceId, sessionId, filters }) {
    state.loadingAttendees = true;
    try {
      const response = await ConferenceService.getSessionAttendees(conferenceId, sessionId, filters);
      context.commit("setConferenceSessionAttendees", response);
      return response;
    } catch (error) {
      throw error;
    } finally {
      state.loadingAttendees = false;
    }
  },

  async fetchConferenceAttendees(context, { conferenceId, filters }) {
    state.loadingAttendees = true;
    try {
      const response = await ConferenceService.getAttendees(conferenceId, filters);
      context.commit("setConferenceAttendees", response);
      return response;
    } catch (error) {
      throw error;
    } finally {
      state.loadingAttendees = false;
    }
  },

  async fetchConferenceAttendee(context, { conferenceId, attendeeId }) {
    try {
      const response = await ConferenceService.getAttendee(conferenceId, attendeeId);
      context.commit("setConferenceAttendee", response);
      return response;
    } catch (error) {
      await router.push({ name: "app.conference.attendees" });
      throw error;
    }
  },

  async fetchConferenceCurrentAttendee(context, { conferenceId }) {
    try {
      const response = await ConferenceService.getCurrentAttendee(conferenceId);
      context.commit("setConferenceCurrentAttendee", response.attendee);
      return response;
    } catch (error) {
      throw error;
    }
  },

  handleAttendeeUpdated(context, data) {
    if (context.state.currentAttendee.id == data.id) {
      context.commit("setConferenceCurrentAttendee", data);
      Store.commit("auth/updateUser", data);
    }
  },

  handleAttendeeDeleted(context, data) {
    if (context.state.currentAttendee.id == data.id) {
      router.push("/");
    }
  },

  updateActiveAttendees(context, attendees) {
    context.state.activeAttendees = attendees;
  },

  async fetchConferenceAnnouncements(context, { conferenceId }) {
    state.loadingAnnouncements = true;
    try {
      const response = await ConferenceService.getAnnouncements(conferenceId);
      const announcements: Array<Announcement> = [];
      for (let i = 0; i < response.length; i++) {
        announcements.push(new Announcement(response[i]));
      }
      context.commit("setConferenceAnnouncements", announcements);
      return response;
    } catch (error) {
      throw error;
    } finally {
      state.loadingAnnouncements = false;
    }
  },

  async handleNewAnnouncement(context, data) {
    if (context.state.conference && data.conference == context.state.conference.id) {
      for (const player in videojs.players) {
        const playerObj = videojs.players[player];
        if (playerObj) {
          if (playerObj.isFullscreen()) {
            playerObj.exitFullscreen();
          }
        }
      }
      const announcement = new Announcement(data);
      context.commit("setConferenceAnnouncement", announcement);
    }
  },

  async claimShowroomCta(context, { conferenceId, showroomId }) {
    try {
      const response = await ConferenceService.claimShowroomCta(conferenceId, showroomId);
      Vue.prototype.$events.$emit("nice-toast", Vue.prototype.$gettext("Claimed"), "success");
      return response;
    } catch (error) {
      throw error;
    }
  },

  async getBlacklisted(context, invitationKey: string) {
    try {
      const response: any = await ConferenceService.isBlacklisted(invitationKey);
      context.commit("setBlacklisted", response.blacklisted);
    } catch (error) {
      throw error;
    }
  },

  async register(context, invitationKey: string) {
    try {
      const response = await ConferenceService.registration(invitationKey);
      return response;
    } catch (error) {
      throw error;
    }
  },

  leaveConferenceStage(context) {
    context.dispatch("recordStageWatchStop", context.state.stage.id);
    context.commit("setConferenceStage", null);
    context.commit("setConferenceStagePolls", null);
    state.loadingPolls = false;
    state.hasNewPoll = false;
  },

  leaveConference(context) {
    context.commit("setConference", null);
    context.commit("setConferenceStages", null);
    context.commit("setConferenceStage", null);
    context.commit("setConferenceStagePolls", []);
    state.loadingPolls = false;
    state.hasNewPoll = false;
  },

  handleStreamChange(context: any, data: any) {
    if (context.state.conference && data.conference == context.state.conference.id) {
      const stage: StageModel = context.state.stages.find((t) => data.stage == t.id);
      if (stage) {
        if (data.is_live)
          Vue.prototype.$events.$emit(
            "nice-toast",
            Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Stage '%{ stage_name }' is live!"), { stage_name: stage.name }),
            "success"
          );
        else
          Vue.prototype.$events.$emit(
            "nice-toast",
            Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("Stage '%{ stage_name }' ended."), { stage_name: stage.name })
          );
        stage.updateStream(data.is_live, data.link);
        stage.update();
        if (context.state.stage && data.stage == context.state.stage.id) {
          context.state.stage.updateStream(data.is_live, data.link);
          context.state.stage.update();
        }
      }
    }
  },

  handleSessionVideoChange(context: any, data: any) {
    // TODO implement activity log
    // console.warn("TODO WS response - handleSessionVideoChange context=, data=", context, data); // TODO Sessions
    if (context.state.session) {
      if (data.message) {
        Vue.prototype.$events.$emit("nice-toast", data.message, "success");
      }
      // this is also called when joining the call
      context.dispatch("fetchConferenceSession", { conferenceId: context.state.conference.id, sessionId: context.state.session.id });
    }
  },

  handleSessionVideoClosed(context: any, data: any) {
    context.commit("updateConferenceSession", data);
    // TODO implement activity log
    // console.warn("TODO WS response - handleSessionVideoClosed context.state=", context.state, "data=", data); // TODO Sessions
    // if (context.state.session && context.state.session.id == data.id) {
    //   if (data.message) {
    //     Vue.prototype.$events.$emit("nice-toast", data.message, "success");
    //   }
    //   // context.dispatch("fetchConferenceSession", { conferenceId: context.state.conference.id, sessionId: context.state.session.id });
    //   // context.dispatch("fetchConferenceSessions", { conferenceId: context.state.conference.id });
    // }
    // if (context.state.sessions) {
    //   // context.dispatch("fetchConferenceSessions", { conferenceId: context.state.conference.id });
    // }
  },

  updateStageViewCounter(context: any, data: any) {
    const stage = state.stages.find((t) => data.stage_id == t.id);
    if (stage) {
      stage.current_viewer_count = data.viewers;
      if (context.state.stage && context.state.stage.id === data.stage_id) {
        context.state.stage.current_viewer_count = data.viewers;
      }
    }
  },

  async recordStageWatchStart(context: any, stageId: number) {
    const payload = {
      type: "STAGE_WATCH_START",
      data: {
        stage_id: stageId,
      },
    };
    if (SocketService.connected) {
      await SocketService.send(payload);
    } else if (SocketManagementService.connected) {
      await SocketManagementService.send(payload);
    }
    return stageId;
  },

  async recordStageWatchStop(context: any, stageId: number) {
    const payload = {
      type: "STAGE_WATCH_STOP",
      data: {
        stage_id: stageId,
      },
    };
    if (SocketService.connected) {
      await SocketService.send(payload);
    } else if (SocketManagementService.connected) {
      await SocketManagementService.send(payload);
    }
    return stageId;
  },

  async recordRecordingTime(context: any, { recordingId, currentTime }) {
    const payload = {
      type: "RECORDING_WATCH_TIME",
      data: {
        recording_id: recordingId,
        current_time: currentTime,
      },
    };
    await SocketService.send(payload);
    return recordingId;
  },

  async recordShowroomAttachmentDownloaded(context: any, attachmentId) {
    const payload = {
      type: "SHOWROOM_ATTACHMENT_DOWNLOAD",
      data: {
        attachment_id: attachmentId,
      },
    };
    await SocketService.send(payload);
    return attachmentId;
  },

  async recordShowroomVideoViewed(context: any, videoId) {
    const payload = {
      type: "SHOWROOM_VIDEO_VIEWED",
      data: {
        video_id: videoId,
      },
    };
    await SocketService.send(payload);
    return videoId;
  },

  async recordShowroomStartMeetingClicked(context: any, showroomId) {
    const payload = {
      type: "SHOWROOM_START_MEETING_CLICKED",
      data: {
        showroom_id: showroomId,
      },
    };
    await SocketService.send(payload);
    return showroomId;
  },

  async recordShowroomEmailClicked(context: any, showroomId) {
    const payload = {
      type: "SHOWROOM_EMAIL_CLICKED",
      data: {
        showroom_id: showroomId,
      },
    };
    await SocketService.send(payload);
    return showroomId;
  },

  async recordSessionAttachmentDownloaded(context: any, attachmentId) {
    const payload = {
      type: "SESSION_ATTACHMENT_DOWNLOAD",
      data: {
        attachment_id: attachmentId,
      },
    };
    await SocketService.send(payload);
    return attachmentId;
  },

  async recordSessionVideoViewed(context: any, videoId) {
    const payload = {
      type: "SESSION_VIDEO_VIEWED",
      data: {
        video_id: videoId,
      },
    };
    await SocketService.send(payload);
    return videoId;
  },

  async fetchTwilioToken(context: any, { conferenceId, sessionId }) {
    try {
      const response = await ConferenceService.startSessionVideo(conferenceId, sessionId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async stopTwilioVideo(context: any, { conferenceId, sessionId, type }) {
    try {
      const response = await ConferenceService.endSessionVideo(conferenceId, sessionId, type);
      return response;
    } catch (error) {
      throw error;
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  stopAllPlayers(context: any) {
    for (const player in videojs.players) {
      const playerObj = videojs.players[player];
      if (playerObj) {
        playerObj.pause();
        if (playerObj.isInPictureInPicture()) {
          playerObj.exitPictureInPicture();
        }
      }
    }
  },
};

// ---------------------------------------------------------------- Model ----------------------------------------------------------------
const conference = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default conference;
