import Vue from "vue";
import MeetingService from "@/services/api/meetings.service";
import MeetingModel from "@/models/meetings.model";
import Router from "@/router";
import ErrorService from "@/services/error.service";
import Store from "@/store";
import NotificationModel from "@/models/notification.model";
import ChatService from "@/services/api/chat.service";
import CommonService from "@/services/common.service";

interface MeetingsStateI {
  hasNewMeeting: boolean;

  loadingMeetings: boolean;
  meetings: MeetingModel[];

  meeting: number | null;
  focusedMeeting: number | null;
  loadingMeeting: boolean;

  inCall: boolean;
  activeCalls: any[];
  popupActiveCalls: number[];

  activeMeetings: number[];
  createNewMeeting: boolean;
}

// ---------------------------------------------------------------- State ----------------------------------------------------------------
const state: MeetingsStateI = {
  hasNewMeeting: false,
  loadingMeetings: false,
  loadingMeeting: false,
  meetings: [],
  meeting: null,
  focusedMeeting: null,
  inCall: false,

  activeCalls: [],
  popupActiveCalls: [],

  activeMeetings: [],
  createNewMeeting: false,
};

// ---------------------------------------------------------------- Getters ----------------------------------------------------------------
const getters = {
  hasNewMeetingMessage(): boolean {
    if (state.hasNewMeeting) {
      return true;
    }

    for (const index in state.meetings) {
      const meeting: MeetingModel = state.meetings[index];
      if (meeting.hasNewMessage) {
        return true;
      }
    }

    return false;
  },

  hasActiveCall(): boolean {
    return state.activeCalls.some((x) => x.active);
  },

  sortedMeetings(): MeetingModel[] {
    return state.meetings.sort((a, b) => {
      const firstInCall = state.activeCalls.some((x) => x.chat == a.id);
      const secondInCall = state.activeCalls.some((x) => x.chat == b.id);
      if (firstInCall && !secondInCall) {
        return -1;
      } else if (!firstInCall && secondInCall) {
        return 1;
      }

      const firstTime = a.last_message ? new Date(a.last_message.created).getTime() : new Date(a.created).getTime();
      const secondTime = b.last_message ? new Date(b.last_message.created).getTime() : new Date(b.created).getTime();

      return secondTime - firstTime;
    });
  },

  unreadMeetings(): number {
    return state.meetings.filter((x) => x.hasNewMessage).length;
  },

  activeCallsLength(): number {
    return state.activeCalls.filter((x) => x.active).length;
  },

  activeMeetings(): MeetingModel[] {
    if (state.meeting) return [];

    return state.activeMeetings
      .map((meetingId) => state.meetings.filter((meeting) => meeting.id != state.meeting).find((meeting) => meeting.id === meetingId))
      .filter(Boolean);
  },

  activeCallMeetings(): any[] {
    return state.activeCalls
      .map((x) => {
        return {
          ...x,
          chat: state.meetings.find((meeting) => meeting.id == x.chat),
        };
      })
      .filter((x) => x.chat);
  },

  firstActiveMeetingId(state, getters): number {
    return getters.activeMeetings.length > 0 ? getters.activeMeetings[0].id : null;
  },

  meetingId(): number {
    return state.meeting;
  },

  meeting(): MeetingModel {
    if (!state.meeting) return null;
    return state.meetings.find((x) => x.id == state.meeting);
  },
};

// ---------------------------------------------------------------- Mutations --------------------------------------------------------------
const mutations = {
  setMeetings(state: MeetingsStateI, meetings: MeetingModel[]) {
    meetings.forEach((meeting) => {
      meeting.hasNewMessage = false;
    });
    state.meetings = meetings;
  },

  addMeeting(state: MeetingsStateI, meeting: MeetingModel) {
    const itemIndex = state.meetings.findIndex((p) => meeting.id == p.id);
    if (itemIndex >= 0) {
      Vue.set(state.meetings, itemIndex, meeting);
    } else {
      state.meetings.unshift(meeting);
    }
  },

  setMeeting(state: MeetingsStateI, meeting: number) {
    state.meeting = meeting;
  },

  setFocusedMeeting(state: MeetingsStateI, channelName: string) {
    const meeting = state.meetings.find((meeting) => meeting.chat_route_key == channelName);
    if (meeting) {
      state.focusedMeeting = meeting.id;
    }
  },

  unsetFocusedMeeting(state: MeetingsStateI, channelName: string) {
    const meeting = state.meetings.find((meeting) => meeting.chat_route_key == channelName);
    if (meeting && meeting.chat_route_key == channelName) {
      state.focusedMeeting = null;
    }
  },

  readFocusedMeeting(state: MeetingsStateI) {
    const meetingIndex = state.meetings.findIndex((meeting) => meeting.id == state.focusedMeeting);
    if (meetingIndex >= 0) {
      const newMeeting = state.meetings[meetingIndex];
      Vue.set(state.meetings, meetingIndex, { ...newMeeting, unreadMessages: 0, hasNewMessage: false, last_read: new Date() });
    }
  },

  setActiveMeeting(state: MeetingsStateI, meetingId: number) {
    const index = state.activeMeetings.indexOf(meetingId);
    if (index >= 0) {
      state.activeMeetings.splice(index, 1);
    } else if (state.activeMeetings.length > 3) {
      state.activeMeetings.pop();
    }
    state.activeMeetings.unshift(meetingId);
  },

  removeActiveMeeting(state: MeetingsStateI, meetingId: number) {
    const index = state.activeMeetings.indexOf(meetingId);
    if (index >= 0) {
      state.activeMeetings.splice(index, 1);
    }
  },

  clearActiveMeetings(state: MeetingsStateI) {
    state.activeMeetings = [];
  },

  setActiveCalls(state: MeetingsStateI, data) {
    state.activeCalls = data;
  },

  updateActiveCall(state: MeetingsStateI, data) {
    const index = state.activeCalls.findIndex((x) => x.chat == data.chat);
    if (index >= 0 && data.active) {
      Vue.set(state.activeCalls, index, data);
    } else if (index >= 0 && !data.active) {
      state.activeCalls.splice(index, 1);
    } else {
      state.activeCalls.push(data);
    }
  },

  setLastReads(state: MeetingsStateI, data: any) {
    state.meetings.forEach((meeting) => {
      const date = data.find((x) => x.chat == meeting.id)?.last_read;
      if (date) {
        meeting.last_read = new Date(date);
        if (meeting.last_message?.created) {
          meeting.hasNewMessage = new Date(meeting.last_message.created) > meeting.last_read;
        }
      }
    });
  },

  setCreateNewMeeting(state: MeetingsStateI, createNewMeeting: boolean) {
    state.createNewMeeting = createNewMeeting;
  },

  setInCall(state: MeetingsStateI, inCall: boolean) {
    state.inCall = inCall;
  },

  unsetMeeting(state: MeetingsStateI) {
    state.meeting = null;
  },

  setHasNewMeeting(state: MeetingsStateI, hasNewMeeting: boolean) {
    state.hasNewMeeting = hasNewMeeting;
  },

  cleanStore(state: MeetingsStateI) {
    state.meetings = [];
    state.hasNewMeeting = false;
    state.loadingMeetings = false;
    state.meeting = null;

    state.activeMeetings = [];
    state.createNewMeeting = false;
  },
};

// ---------------------------------------------------------------- Actions ----------------------------------------------------------------
const actions = {
  handleNewMessage(context: any, data: Record<string, any>) {
    const meeting = state.meetings.find((m: MeetingModel) => m.chat_route_key == data.chat_channel);
    if (meeting) {
      (meeting as MeetingModel).last_message = {
        id: data.id,
        message: data.message,
        created: data.created,
        attendee: data.attendee.id,
      };
      const currAttendee = Store.getters["conference/currentAttendee"];
      (meeting as MeetingModel).hasNewMessage = true;
      // if (currAttendee.id != data.attendee.id) {
      // }

      if (
        currAttendee.id != data.attendee.id &&
        context.state.meeting != meeting.id &&
        !context.state.activeMeetings.includes(meeting.id)
      ) {
        Store.dispatch("notifications/addCustomNewMessageNotification", {
          meetingId: meeting.id,
          data: {
            avatar: data.attendee.avatar,
            sender: CommonService.combineAttendeeName(data.attendee),
            object: data.message,
            group: meeting.participants.length > 2,
          },
        });
      }
    }
  },

  handleNewChatAttachment(context: any, data: Record<string, any>) {
    const meeting = state.meetings.find((m: MeetingModel) => m.chat_route_key == data.chat_channel);
    if (meeting) {
      if (context.state.meeting != meeting.id && !context.state.activeMeetings.includes(meeting.id)) {
        (meeting as MeetingModel).hasNewMessage = true;
        Vue.prototype.$events.$emit(
          "nice-toast",
          Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("New meeting attachment from '%{ firstname_lastname }'."), {
            firstname_lastname: data.initiator,
          }),
          "success"
        );
      }
    }
  },

  handleNewMeeting(context: any, meeting: MeetingModel) {
    const currAttendee = Store.getters["conference/currentAttendee"];
    Vue.prototype.$events.$emit(
      "nice-toast",
      Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("New meeting with '%{ firstname_lastname }' started."), {
        firstname_lastname: CommonService.combineMeetingName(meeting.participants, currAttendee),
      }),
      "success"
    );
    context.commit("setHasNewMeeting", true);
    context.commit("addMeeting", meeting);
  },

  handleVideoCallUpdated(context: any, call) {
    if (call.type == "started" && call.host.id) {
      const currAttendee = Store.getters["conference/currentAttendee"];
      if (currAttendee.id != call.host.id) {
        Store.dispatch("notifications/handleNotificationUpdated", {
          id: "call-" + call.chat,
          notification_type: "NEW_CALL",
          updated: new Date(),
          object_id: call.chat,
          data: {
            senderId: call.host.id,
            sender: call.host.full_name,
            image: call.host.avatar,
            group: call.group,
            chatName: call.chat_name,
          },
        });
      }
    } else if (call.type == "ended") {
      Store.dispatch("notifications/handleNotificationDeleted", "call-" + call.chat);
    }
    context.commit("updateActiveCall", call);
  },

  handleNotificationRead(context: any, notification: NotificationModel) {
    if (["NEW_MESSAGE"].includes(notification.notificationType)) {
      const meeting = context.state.meetings.find((m: MeetingModel) => m.id == notification.objectId);
      // if (meeting) (meeting as MeetingModel).hasNewMessage = false;
    }
  },

  handleAllNotificationsRead(context: any) {
    context.state.meetings.forEach((meeting: MeetingModel) => {
      meeting.hasNewMessage = false;
    });
  },

  handleVideoStarted(context, data) {
    // Happens on a user calling you. Should give notification and notify other if u are already in a call.
    if (Router.currentRoute.query.call === "active") {
      if (data.object_id !== context.state.meeting) {
        // IF user is attempting call to an already active user, send response
        ChatService.stopVideo(data.data.chatName, "inCall");
      }
    }
    // Add notification
    Store.dispatch("notifications/handleNotificationUpdated", data);
  },

  handleVideoEnded(context, data) {
    // For non group call a notification is sent from backend, so just delete the old one
    // if (data.groupCall) {
    //   Store.dispatch("notifications/updateCustomMissedCallNotification", { meetingId: data.meeting_id });
    // } else {
    //   Store.dispatch("notifications/handleNotificationDeleted", "call-" + data.meeting_id);
    // }

    if (Router.currentRoute.query.call !== "active" || data.meeting_id != Router.currentRoute.params.meetingId) {
      return;
    }
    if (data.type === "rejected") {
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("%{ caller } has refused your call"), {
          caller: data.sender,
        }),
        "warning"
      );
    } else if (data.type === "inCall") {
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("%{ caller } is in an active call"), {
          caller: data.sender,
        }),
        "warning"
      );
    } else {
      Vue.prototype.$events.$emit(
        "nice-toast",
        Vue.prototype.$gettextInterpolate(Vue.prototype.$gettext("%{ caller } has ended the call"), {
          caller: data.sender,
        }),
        "warning"
      );
    }
    // Router.replace({ query: { video: Router.currentRoute.query.video } });
  },

  async fetchLastReads(context: any, { conferenceId, filters }) {
    const response = await MeetingService.getReads(conferenceId, filters);
    context.commit("setLastReads", response);
    return response;
  },

  async fetchActiveCalls(context: any, { conferenceId }) {
    const response = await MeetingService.getActiveCalls(conferenceId);
    context.commit("setActiveCalls", response);
    return response;
  },

  async fetchMeetings(context: any, { conferenceId, filters }) {
    context.state.loadingMeetings = true;
    try {
      const response = await MeetingService.getAll(conferenceId, filters);
      context.commit("setMeetings", response);
      return response;
    } catch (error) {
      throw error;
    } finally {
      context.state.loadingMeetings = false;
    }
  },

  async fetchMeeting(context: any, { conferenceId, meetingId }) {
    if (context.state.meeting == meetingId) {
      return context.state.meetings.find((x) => x.id == context.state.meeting);
    } else {
      try {
        context.commit("clearActiveMeetings");
        context.state.loadingMeeting = true;
        const response = await MeetingService.get(conferenceId, meetingId);
        context.commit("addMeeting", response);
        context.commit("setMeeting", response.id);
        return response;
      } catch (error) {
        throw error;
      } finally {
        context.state.loadingMeeting = false;
      }
    }
  },

  async startNewMeeting(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await MeetingService.startNew(conferenceId, attendeeId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchWherebyRoom(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await MeetingService.startVideo(conferenceId, attendeeId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchToken(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await MeetingService.startVideo(conferenceId, attendeeId);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async stopVideo(context: any, { conferenceId, attendeeId, type }) {
    try {
      const response = await MeetingService.endVideo(conferenceId, attendeeId, type);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async fetchAttachmentHistory(context: any, { chatRouteKey }) {
    try {
      const response = await MeetingService.getAttachments(chatRouteKey);
      return response;
    } catch (error) {
      throw error;
    }
  },

  async goToMeetingMultiple(context: any, { conferenceId, attendees }) {
    try {
      const response = await MeetingService.startNewMultiple(conferenceId, attendees);
      context.commit("addMeeting", response);
      context.dispatch("goToMeetingById", response.id);
    } catch (error) {
      ErrorService.handleError(error);
    }
  },

  async goToNewMeeting(context: any) {
    const windowWidth = Store.getters["general/windowWidth"];
    if (windowWidth > 650 && !context.state.meeting) {
      context.commit("setCreateNewMeeting", true);
    } else {
      Router.push({ name: "app.conference.meeting.new" });
    }
  },

  async goToMeetingById(context: any, meetingId) {
    context.commit("setCreateNewMeeting", false);

    if (context.state.meeting == meetingId) return;

    const windowWidth = Store.getters["general/windowWidth"];
    if (windowWidth > 650 && !context.state.meeting) {
      context.commit("setActiveMeeting", meetingId);
    } else {
      Router.push({ name: "app.conference.meeting", params: { meetingId: meetingId } });
    }
  },

  async goToMeeting(context: any, { conferenceId, attendeeId }) {
    try {
      const response = await MeetingService.startNew(conferenceId, attendeeId);
      context.commit("addMeeting", response);
      context.dispatch("goToMeetingById", response.id);
    } catch (error) {
      ErrorService.handleError(error);
    }
  },

  async tryToReadMeeting(context: any, channelName) {
    const meeting = context.state.meetings.find(
      (meeting) => meeting.chat_route_key == channelName && context.state.focusedMeeting == meeting.id
    );
    if (!meeting || !meeting.hasNewMessage) return;
    try {
      context.commit("readFocusedMeeting");
      ChatService.readChatMessages(channelName);
    } catch (error) {
      ErrorService.handleError(error);
    }
  },
};

// ---------------------------------------------------------------- Model ----------------------------------------------------------------
const meetings = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default meetings;
