import Vue from "vue";
import Store from "@/store";

export default {
  path: "/live",
  name: "live",
  component: () => import("../views/LiveView.vue"),
  meta: { title: Vue.prototype.$gettext("Live"), public: true },
  children: [
    {
      path: ":code",
      name: "live.code",
      component: () => import("../views/live/QRCodeView.vue"),
      meta: {
        title: Vue.prototype.$gettext("Login"),
        public: true,
      },
    },
  ],
};
