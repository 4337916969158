import RequestService from "@/services/request.service";
import { AppError } from "@/services/error.service";
import TokenService from "@/services/token.service";
import StageModel from "@/models/stage.model";

const ConferenceService = {
  getAll: async function (filters: any) {
    try {
      const path = "/api/v1/conferences/";
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  get: async function (conferenceId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getGeneralInfo: async function (conferenceId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/general/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDetailsInfo: async function (conferenceId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/details/`;
      const response = await RequestService.get(path);
      const stages: Array<StageModel> = [];
      const stages_list = response.stages_list;
      for (let i = 0; i < stages_list.length; i++) {
        stages.push(new StageModel(stages_list[i]));
      }
      stages["_metadata"] = response._metadata;
      response.stages_list = stages;
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  getStages: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/stages/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getStage: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/stages/${stageId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getShowrooms: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/showrooms/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getShowroom: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/showrooms/${showroomId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getShowroomGroups: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/showroom-groups/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  canClaimShowroomCta: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/showrooms/${showroomId}/can-register-interest/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  claimShowroomCta: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/showrooms/${showroomId}/register-interest/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getSessions: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/sessions/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getSession: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/sessions/${sessionId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getSessionWherebyRoomUrl: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/sessions/${sessionId}/wb-room-url/`;
      return (await RequestService.get(path))["wb_room_url"];
    } catch (error) {
      throw new AppError(error);
    }
  },

  getStageVirtualStudioRoomUrl: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/stages/${stageId}/wb-room-url/`;
      return (await RequestService.get(path))["wb_room_url"];
    } catch (error) {
      throw new AppError(error);
    }
  },

  getSessionAttendees: async function (conferenceId: number, sessionId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/sessions/${sessionId}/room/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  startSessionVideo: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/sessions/${sessionId}/new-video/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  endSessionVideo: async function (conferenceId: number, sessionId: number, type: string) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/sessions/${sessionId}/stop-video/`;
      const data = {
        type: type,
      };
      return await RequestService.get(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getAnnouncements: async function (conferenceId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/announcements/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getPolls: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/stages/${stageId}/polls/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  pollVote: async function (conferenceId: number, stageId: number, pollId: number, choiceId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/stages/${stageId}/polls/${pollId}/vote/`;
      const data = {
        choice: choiceId,
      };
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  registration: async function (invitation_key: string) {
    try {
      const path = "/api/v1/conferences/registration/";
      const data = {
        invitation_key,
      };
      const response = await RequestService.post(path, data, null, {});
      TokenService.setAccessToken(response.access);
      TokenService.setRefreshToken(response.refresh);
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  isBlacklisted: async function (invitation_key: string) {
    try {
      const path = "/api/v1/conferences/blacklisted/";
      const data = {
        invitation_key,
      };
      const response = await RequestService.post(path, data, null, {});
      return response;
    } catch (error) {
      throw new AppError(error);
    }
  },

  // ----------------------------- Attendees -----------------------------
  getAttendees: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/attendees/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getAttendee: async function (conferenceId: number, attendeeId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/attendees/${attendeeId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getCurrentAttendee: async function (conferenceId: number) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/attendees/current/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default ConferenceService;
