<template>
  <div class="breadcrumbs">
    <div class="breadcrumb" v-for="(breadcrumb, index) in breadcrumbs" :key="index">
      <router-link :to="{ name: breadcrumb.name }">{{ breadcrumb.title }}</router-link>
      <span class="seperator">></span>
    </div>
  </div>
</template>

<script lang="js">
import Vue from "vue";
import CommonService from "@/services/common.service";

export default Vue.extend({
  name: "ManagementBreadcrumbs",

  computed: {
    routerTitles: CommonService.routerTitles,

    breadcrumbs() {
      const pathArray = this.$route.name.split(".");
      const breadcrumbs = [];
      let paths = this.$router.options.routes;
      let path = "";

      for (const key in pathArray) {
        path += (key != 0 ? "." : "") + pathArray[key];
        const found = paths.find(p => p.name == path);
        paths = found.children;
        if (found) {
          breadcrumbs.push({
            title: this.routerTitles[found.name] || found.meta.title,
            meta: found.meta,
            name: found.name,
            path: found.path
          });
        }
      }
      breadcrumbs.pop()
      return breadcrumbs;
    }
  },

});
</script>

<style scoped lang="scss">
.breadcrumbs {
  display: flex;
  align-items: center;

  .breadcrumb {
    display: flex;
    align-items: center;

    .seperator {
      padding: 0 0.4rem;
      font-size: 0.5em;
      line-height: 1;
    }

    a {
      &:hover {
        text-decoration: underline;
      }
    }

    &:last-child {
      .seperator {
        display: none;
      }
    }
  }
}
</style>
