import Vue from "vue";
import VueRouter, { Route, RouteConfig, RawLocation } from "vue-router";

import BeforeEach from "@/router/before-each.js";

import AppRouter from "@/router/app.router";
import ManagementRouter from "@/router/management.router";
import PublicRouter from "@/router/public.router";
import LiveRouter from "@/router/live.router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    redirect: { name: "loading" },
  },
  {
    path: "*",
    redirect: { name: "loading" },
  },
  {
    path: "",
    redirect: { name: "loading" },
  },
  {
    path: "/loading",
    name: "loading",
    component: () => import("../views/LoadingView.vue"),
    meta: { title: Vue.prototype.$gettext("Loading"), allowLiveAttendee: true },
  },
  // {
  //   path: "/zoom",
  //   name: "zoom",
  //   component: () => import("@/components/app/ZoomWebinar.vue"),
  //   meta: { title: Vue.prototype.$gettext("Virtual studio") },
  // },
  {
    path: "/oauth/zoom",
    name: "zoom-redirect",
    component: () => import("@/components/app/ZoomWebinarRedirect.vue"),
    meta: { title: Vue.prototype.$gettext("Zoom OAuth redirect") },
  },
  ...PublicRouter,
  AppRouter,
  ManagementRouter,
  LiveRouter,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    // handle page scrolling when navigating to a new route, or back to the previous route
    return { x: 0, y: 0 };
    // if (to.hash) { // scroll to the HTML anchor
    //   return new Promise(resolve => {
    //     setTimeout(() => {
    //       resolve({ selector: to.hash });
    //     }, 1000);
    //   });
    // } else if (savedPosition) { // scroll to last position on the previous page
    //   return savedPosition;
    // } else { // scroll on top
    //   return { x: 0, y: 0 };
    // }
  },
  routes,
});

router.beforeEach(BeforeEach);

// https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
// https://forum.vuejs.org/t/typescript-vu-router-push/101572
// override replace to ignore router NavigationDuplicated error
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalReplace.call(
      this,
      location,
      () => {
        // on complete

        resolve(this.currentRoute);
      },
      (error) => {
        // on abort
        // only ignore NavigationDuplicated and navigation cancelled error
        if (
          error.name === "NavigationDuplicated" ||
          error.message.includes("Avoided redundant navigation to current location") ||
          error.message.includes("Navigation cancelled")
        ) {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

// https://stackoverflow.com/questions/62462276/how-to-solve-avoided-redundant-navigation-to-current-location-error-in-vue
// https://forum.vuejs.org/t/typescript-vu-router-push/101572
// override push to ignore router NavigationDuplicated error
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location: RawLocation): Promise<Route> {
  return new Promise((resolve, reject) => {
    originalPush.call(
      this,
      location,
      () => {
        // on complete

        resolve(this.currentRoute);
      },
      (error) => {
        // on abort
        // only ignore NavigationDuplicated and Navigation cancelled error
        if (
          error.name === "NavigationDuplicated" ||
          error.message.includes("Avoided redundant navigation to current location") ||
          error.message.includes("Navigation cancelled")
        ) {
          resolve(this.currentRoute);
        } else {
          reject(error);
        }
      }
    );
  });
};

export default router;
