<template>
  <div id="app">
    <router-view />
    <NiceToast />
    <NiceNotifications />
    <ConfirmModalEvent />
    <Svgs />
  </div>
</template>

<script>
import Svgs from "@/components/Svgs";
import ConfirmModalEvent from "@/components/modals/ConfirmModalEvent.vue";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "Index",

  components: {
    Svgs,
    ConfirmModalEvent,
  },

  methods: {
    ...mapMutations("general", ["setWindowWidth", "setWindowHeight", "updateClock", "setCurrentRoute"]),

    onWindowResize() {
      this.setWindowWidth(window.innerWidth);
      this.setWindowHeight(window.innerHeight);
    },
  },

  watch: {
    $route() {
      this.setCurrentRoute(this.$route);
    },
  },

  mounted() {
    this.setCurrentRoute(this.$route);
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();

    setInterval(() => {
      this.updateClock();
    }, 10000);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onWindowResize);
  },
};
</script>

<style lang="scss">
@import "@/styles/main";
</style>
