import RequestService from "@/services/request.service";
import { AppError } from "@/services/error.service";
import { ExportFileFormat } from "@/models/enums";
// import GroupModel from '@/models/group.model';
// import StageModel from "@/models/stage.model";
// import ConferenceModel from "@/models/conference.model";

const ManagementService = {
  // Conference
  getConferences: async function (filters: any) {
    try {
      const path = "/api/v1/management/conferences/";
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getConference: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateConference: async function (conference: any) {
    try {
      const path = `/api/v1/management/conferences/${conference.id}/`;
      const data = new FormData();
      if (conference.cover_photo instanceof File) data.append("cover_photo", conference.cover_photo);
      else if (!conference.cover_photo) data.append("cover_photo", "");
      if (conference.thumbnail_photo instanceof File) data.append("thumbnail_photo", conference.thumbnail_photo);
      else if (!conference.thumbnail_photo) data.append("thumbnail_photo", "");
      data.append("name", conference.name);
      data.append("start_date", new Date(conference.start_date).toISOString());
      data.append("end_date", new Date(conference.end_date).toISOString());
      data.append("custom_logo", conference.custom_logo);
      data.append("description", conference.description || "");
      data.append("long_description", conference.long_description || "");
      data.append("enable_chat", conference.enable_chat);
      data.append("color", conference.color || "");
      data.append("has_stages", conference.has_stages);
      data.append("has_showrooms", conference.has_showrooms);
      data.append("has_sessions", conference.has_sessions);
      data.append("has_attendees", conference.has_attendees);
      data.append("has_meetings", conference.has_meetings);
      data.append("dark_mode", conference.dark_mode || "false");
      data.append("language", conference.language);
      return await RequestService.put(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateConferenceSettings: async function (conferenceId: number, data: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/update-settings/`;
      return await RequestService.put(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Stages
  getStages: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getStage: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareStage(stage: any) {
    const data = new FormData();
    if (stage.cover_photo instanceof File) data.append("cover_photo", stage.cover_photo);
    else if (!stage.cover_photo) data.append("cover_photo", "");
    if (stage.thumbnail_photo instanceof File) data.append("thumbnail_photo", stage.thumbnail_photo);
    else if (!stage.thumbnail_photo) data.append("thumbnail_photo", "");

    // if (stage.mux_studio_background_photo instanceof File) data.append("mux_studio_background_photo", stage.mux_studio_background_photo);
    // else if (!stage.mux_studio_background_photo) data.append("mux_studio_background_photo", "");
    // if (stage.mux_studio_overlay_photo instanceof File) data.append("mux_studio_overlay_photo", stage.mux_studio_overlay_photo);
    // else if (!stage.mux_studio_overlay_photo) data.append("mux_studio_overlay_photo", "");

    data.append("name", stage.name);
    data.append("start_date", new Date(stage.start_date).toISOString());
    data.append("end_date", new Date(stage.end_date).toISOString());
    // data.append("enable_viewer_counter", stage.enable_viewer_counter);
    // data.append("viewer_counter_visible", stage.viewer_counter_visible);
    data.append("description", stage.description || "");
    data.append("long_description", stage.long_description || "");
    data.append("enable_chat", stage.enable_chat);
    data.append("enable_virtual_studio", stage.enable_virtual_studio);
    data.append("virtual_studio_host_group", stage.virtual_studio_host_group || "");
    data.append("enable_polls", stage.enable_polls);
    data.append("enable_qa", stage.enable_qa);

    if (stage.qa && stage.enable_qa) {
      data.append("qa_allow_anonymous", stage.qa.allow_anonymous);
      data.append("qa_private", stage.qa.private);
      stage.qa.moderators.forEach((moderator) => {
        data.append("qa_moderators", moderator.id);
      });
    }

    stage.groups.forEach((group) => {
      data.append("groups", group.id);
    });
    stage.virtual_studio_guest_groups.forEach((group) => {
      data.append("virtual_studio_guest_groups", group.id);
    });
    return data;
  },

  createStage: async function (conferenceId: number, stage: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/`;
      const data = this._prepareStage(stage);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateStage: async function (conferenceId: number, stage: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stage.id}/`;
      const data = this._prepareStage(stage);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteStage: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  duplicateStage: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/duplicate/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateStageStreamLatency: async function (conferenceId: number, stageId: number, latencyMode: string) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/update-latency-mode/`;
      const data = new FormData();
      data.append("latency_mode", latencyMode);
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateStagesOrder: async function (conferenceId: number, stages: any) {
    try {
      const stagesOrder = stages.map((x) => {
        // only send necessary data
        return { id: x.id, order_position: x.order_position };
      });
      const path = `/api/v1/management/conferences/${conferenceId}/stages/update-stages-order/`;
      return await RequestService.patch(path, stagesOrder);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getStageLiveQRCode: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/qr-code/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Showrooms
  getShowrooms: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getShowroom: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${stageId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareShowroom(showroom: any) {
    const data = new FormData();
    if (showroom.cover_photo instanceof File) data.append("cover_photo", showroom.cover_photo);
    else if (!showroom.cover_photo) data.append("cover_photo", "");
    if (showroom.thumbnail_photo instanceof File) data.append("thumbnail_photo", showroom.thumbnail_photo);
    else if (!showroom.thumbnail_photo) data.append("thumbnail_photo", "");
    data.append("description", showroom.description || "");
    data.append("long_description", showroom.long_description || "");
    data.append("name", showroom.name);
    data.append("enable_chat", showroom.enable_chat);
    data.append("enable_polls", showroom.enable_polls);
    data.append("enable_cta", showroom.enable_cta);
    // data.append("promoted", showroom.promoted);
    data.append("meeting_attendee", showroom.meeting_attendee || "");
    data.append("contact_email", showroom.contact_email || "");
    data.append("enable_qa", showroom.enable_qa);

    if (showroom.qa && showroom.enable_qa) {
      data.append("qa_allow_anonymous", showroom.qa.allow_anonymous);
      data.append("qa_private", showroom.qa.private);
      showroom.qa.moderators.forEach((moderator) => {
        data.append("qa_moderators", moderator.id);
      });
    }

    showroom.groups.forEach((group) => {
      data.append("groups", group.id);
    });

    if (showroom.cta_icon instanceof File) data.append("cta_icon", showroom.cta_icon);
    else if (!showroom.cta_icon) data.append("cta_icon", "");

    data.append("cta_title", showroom.cta_title || "");
    data.append("cta_text", showroom.cta_text || "");
    data.append("cta_disclaimer", showroom.cta_disclaimer || "");
    data.append("cta_button", showroom.cta_button || "");
    return data;
  },

  createShowroom: async function (conferenceId: number, showroom: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/`;
      const data = this._prepareShowroom(showroom);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroom: async function (conferenceId: number, showroom: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroom.id}/`;
      const data = this._prepareShowroom(showroom);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteShowroom: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  duplicateShowroom: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/duplicate/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // showroom groups

  getShowroomGroups: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showroom-groups/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getShowroomGroup: async function (conferenceId: number, showroomGroupId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showroom-groups/${showroomGroupId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareShowroomGroup(showroomGroup: any) {
    const data = new FormData();

    data.append("name", showroomGroup.name);

    return data;
  },

  createShowroomGroup: async function (conferenceId: number, showroomGroup: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showroom-groups/`;
      const data = this._prepareShowroomGroup(showroomGroup);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroomGroup: async function (conferenceId: number, showroomGroup: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showroom-groups/${showroomGroup.id}/`;
      const data = this._prepareShowroomGroup(showroomGroup);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteShowroomGroup: async function (conferenceId: number, showroomGroupId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showroom-groups/${showroomGroupId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroomGroupOrder: async function (conferenceId: number, groups: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showroom-groups/update-order/`;
      return await RequestService.patch(path, groups);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroomsOrder: async function (conferenceId: number, showrooms: any) {
    try {
      const showroomsOrder = showrooms.map((x) => {
        // only send necessary data
        return { id: x.id, order_group: x.order_group, order_position: x.order_position };
      });
      const path = `/api/v1/management/conferences/${conferenceId}/showroom-groups/update-showrooms/`;
      return await RequestService.patch(path, showroomsOrder);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroomVideoOrder: async function (conferenceId: number, showroomId: number, videos: any) {
    try {
      const videoOrder = videos.map((x) => {
        // only send necessary data
        return { id: x.id, order_position: x.order_position };
      });
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/videos/update-order/`;
      return await RequestService.patch(path, videoOrder);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroomAttachmentOrder: async function (conferenceId: number, showroomId: number, attachments: any) {
    try {
      const attachmentOrder = attachments.map((x) => {
        // only send necessary data
        return { id: x.id, order_position: x.order_position };
      });
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/attachments/update-order/`;
      return await RequestService.patch(path, attachmentOrder);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Showroom attachments
  getShowroomAttachments: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/attachments/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareShowroomAttachment(showroom: any) {
    const data = new FormData();
    data.append("name", showroom.name);
    if (showroom.file instanceof File) data.append("file", showroom.file);
    else if (!showroom.file) data.append("file", "");
    return data;
  },

  createShowroomAttachment: async function (conferenceId: number, showroomId: number, showroomAttachment: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/attachments/`;
      const data = this._prepareShowroomAttachment(showroomAttachment);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroomAttachment: async function (conferenceId: number, showroomId: number, showroomAttachment: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/attachments/${showroomAttachment.id}/`;
      const data = this._prepareShowroomAttachment(showroomAttachment);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteShowroomAttachment: async function (conferenceId: number, showroomId: number, atachmentId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/attachments/${atachmentId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Showroom videos
  getShowroomVideos: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/videos/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareShowroomVideo(showroom: any) {
    const data = new FormData();
    data.append("name", showroom.name);
    data.append("source", showroom.source);
    data.append("link", showroom.link);
    if (showroom.thumbnail instanceof File) data.append("thumbnail", showroom.thumbnail);
    else if (!showroom.thumbnail) data.append("thumbnail", "");
    return data;
  },

  createShowroomVideo: async function (conferenceId: number, showroomId: number, showroomVideo: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/videos/`;
      const data = this._prepareShowroomVideo(showroomVideo);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateShowroomVideo: async function (conferenceId: number, showroomId: number, showroomVideo: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/videos/${showroomVideo.id}/`;
      const data = this._prepareShowroomVideo(showroomVideo);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteShowroomVideo: async function (conferenceId: number, showroomId: number, videoId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/videos/${videoId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Showroom CTA registrations
  getShowroomRegistrations: async function (conferenceId: number, showroomId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/cta-registrations/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  exportShowroomRegistrations: async function (
    conferenceId: number,
    showroomId: number,
    fileFormat: ExportFileFormat = ExportFileFormat.csv
  ) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/showrooms/${showroomId}/cta-registrations/export/`;
      const data = {
        file_format: fileFormat,
      };
      return await RequestService.getBlob(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Sessions
  getSessions: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getSession: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${stageId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareSession(session: any) {
    const data = new FormData();
    if (session.cover_photo instanceof File) data.append("cover_photo", session.cover_photo);
    else if (!session.cover_photo) data.append("cover_photo", "");
    if (session.thumbnail_photo instanceof File) data.append("thumbnail_photo", session.thumbnail_photo);
    else if (!session.thumbnail_photo) data.append("thumbnail_photo", "");
    data.append("description", session.description || "");
    data.append("long_description", session.long_description || "");
    data.append("name", session.name);
    data.append("start_date", new Date(session.start_date).toISOString());
    data.append("end_date", new Date(session.end_date).toISOString());
    // data.append("max_attendees_allowed", session.max_attendees_allowed);
    data.append("host_group", session.host_group);
    data.append("use_wb", session.use_wb);
    data.append("record_video", session.record_video);
    data.append("enable_chat", session.enable_chat);
    data.append("session_chat", session.session_chat);
    // data.append("promoted", session.promoted);
    data.append("enable_polls", session.enable_polls);
    data.append("enable_qa", session.enable_qa);
    session.groups.forEach((group) => {
      data.append("groups", group.id);
    });

    if (session.qa && session.enable_qa) {
      data.append("qa_allow_anonymous", session.qa.allow_anonymous);
      data.append("qa_private", session.qa.private);
      session.qa.moderators.forEach((moderator) => {
        data.append("qa_moderators", moderator.id);
      });
    }

    return data;
  },

  createSession: async function (conferenceId: number, session: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/`;
      const data = this._prepareSession(session);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateSession: async function (conferenceId: number, session: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${session.id}/`;
      const data = this._prepareSession(session);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteSession: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  endSession: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/end-video/?type=forced`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  duplicateSession: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/duplicate/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  startSessionCompositions: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/start-compositions/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  statusSessionCompositions: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/status-compositions/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  downloadSessionCompositions: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/download-compositions/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // TODO
  deleteSessionCompositions: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/delete-compositions/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // TODO
  deleteSessionRecordings: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/delete-recordings/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Session attachments
  getSessionAttachments: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/attachments/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareSessionAttachment(session: any) {
    const data = new FormData();
    data.append("name", session.name);
    if (session.file instanceof File) data.append("file", session.file);
    else if (!session.file) data.append("file", "");
    return data;
  },

  createSessionAttachment: async function (conferenceId: number, sessionId: number, sessionAttachment: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/attachments/`;
      const data = this._prepareSessionAttachment(sessionAttachment);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateSessionAttachment: async function (conferenceId: number, sessionId: number, sessionAttachment: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/attachments/${sessionAttachment.id}/`;
      const data = this._prepareSessionAttachment(sessionAttachment);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteSessionAttachment: async function (conferenceId: number, sessionId: number, atachmentId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/attachments/${atachmentId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Session videos
  getSessionVideos: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/videos/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareSessionVideo(session: any) {
    const data = new FormData();
    data.append("name", session.name);
    data.append("source", session.source);
    data.append("link", session.link);
    if (session.thumbnail instanceof File) data.append("thumbnail", session.thumbnail);
    else if (!session.thumbnail) data.append("thumbnail", "");
    return data;
  },

  createSessionVideo: async function (conferenceId: number, sessionId: number, sessionVideo: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/videos/`;
      const data = this._prepareSessionVideo(sessionVideo);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateSessionVideo: async function (conferenceId: number, sessionId: number, sessionVideo: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/videos/${sessionVideo.id}/`;
      const data = this._prepareSessionVideo(sessionVideo);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteSessionVideo: async function (conferenceId: number, sessionId: number, videoId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/videos/${videoId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateSessionsOrder: async function (conferenceId: number, sessions: any) {
    try {
      const sessionsOrder = sessions.map((x) => {
        // only send necessary data
        return { id: x.id, order_position: x.order_position };
      });
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/update-sessions-order/`;
      return await RequestService.patch(path, sessionsOrder);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Stats
  getStageViews: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/stage-views/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getAttendeeStats: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/attendee-stats/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Recordings
  getStageRecordings: async function (conferenceId: number, stageId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/recordings/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getStageRecording: async function (conferenceId: number, stageId: number, recordingId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/recordings/${recordingId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // editStageRecording: async function (conferenceId: number, stageId: number, recordingId: number, thumbnail: any, published: boolean) {
  //   try {
  //     const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/recordings/${recordingId}/`
  //     const data = new FormData()
  //     data.append("thumbnail", thumbnail)
  //     data.append("published", published ? "true" : "false")
  //     return await RequestService.putFormData(path, data)
  //   } catch (error) {
  //     throw new AppError(error)
  //   }
  // },

  createStageRecording: async function (conferenceId: number, stageId: number, form: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/recordings/`;
      const data = new FormData();
      if (form.thumbnail instanceof File) data.append("thumbnail", form.thumbnail);
      else if (!form.thumbnail) data.append("thumbnail", "");
      data.append("name", form.name);
      data.append("custom_link", form.custom_link);
      data.append("published", form.published ? "true" : "false");
      data.append("published_date", form.published_date ? new Date(form.published_date).toISOString() : "");
      data.append("unpublished_date", form.unpublished_date ? new Date(form.unpublished_date).toISOString() : "");
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateStageRecording: async function (conferenceId: number, stageId: number, form: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/recordings/${form.id}/`;
      const data = new FormData();
      if (form.thumbnail instanceof File) data.append("thumbnail", form.thumbnail);
      else if (!form.thumbnail) data.append("thumbnail", "");
      data.append("name", form.name);
      data.append("published", form.published ? "true" : "false");
      data.append("published_date", form.published_date ? new Date(form.published_date).toISOString() : "");
      data.append("unpublished_date", form.unpublished_date ? new Date(form.unpublished_date).toISOString() : "");
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteStageRecording: async function (conferenceId: number, stageId: number, recordingId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/stages/${stageId}/recordings/${recordingId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // SessionRecordings
  getSessionRecordings: async function (conferenceId: number, sessionId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/recordings/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateSessionRecording: async function (conferenceId: number, sessionId: number, form: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/recordings/${form.id}/`;
      const data = new FormData();
      if (form.thumbnail instanceof File) data.append("thumbnail", form.thumbnail);
      else if (!form.thumbnail) data.append("thumbnail", "");
      data.append("name", form.name);
      data.append("published", form.published ? "true" : "false");
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteSessionRecording: async function (conferenceId: number, sessionId: number, recordingId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/sessions/${sessionId}/recordings/${recordingId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Announcements
  getAnnouncements: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/announcements/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getAnnouncement: async function (conferenceId: number, announcementId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/announcements/${announcementId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  createAnnouncement: async function (conferenceId: number, announcement: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/announcements/`;
      const data = this._prepareAnnouncement(announcement);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateAnnouncement: async function (conferenceId: number, announcementId: number, announcement: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/announcements/${announcementId}/`;
      const data = this._prepareAnnouncement(announcement);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteAnnouncement: async function (conferenceId: number, announcementId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/announcements/${announcementId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  duplicateAnnouncement: async function (conferenceId: number, announcementId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/announcements/${announcementId}/duplicate/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareAnnouncement(announcement: any) {
    const formData = new FormData();

    if (announcement.custom_image instanceof File && announcement.cta_type === "CUSTOM")
      formData.append("custom_image", announcement.custom_image);
    else if (!announcement.custom_image) formData.append("custom_image", "");

    if (!announcement.published) {
      formData.append("published_date", announcement.published_date ? new Date(announcement.published_date).toISOString() : "");
    }
    announcement.groups.forEach((group) => {
      formData.append("groups", group.id);
    });

    formData.append("title", announcement.title);
    formData.append("description", announcement.description);
    formData.append("cta_type", announcement.cta_type);
    formData.append("cta_text", announcement.cta_type !== "NO_ACTION" ? announcement.cta_text : "");
    formData.append("expires_minutes", announcement.expires_minutes);
    formData.append("stage", announcement.stage && announcement.cta_type === "STAGE" ? announcement.stage.id : "");
    formData.append("showroom", announcement.showroom && announcement.cta_type === "SHOWROOM" ? announcement.showroom.id : "");
    formData.append("session", announcement.session && announcement.cta_type === "SESSION" ? announcement.session.id : "");
    formData.append("custom_url", announcement.cta_type == "CUSTOM" && announcement.custom_url ? announcement.custom_url : "");

    return formData;
  },

  async publishAnnouncement(conferenceId: number, announcementId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/announcements/${announcementId}/publish/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Conference files
  getConferenceFiles: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/files/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareConferenceFile(file: any) {
    const data = new FormData();
    data.append("name", file.name);
    if (file.file instanceof File) data.append("file", file.file);
    else if (!file.file) data.append("file", "");
    return data;
  },

  createConferenceFile: async function (conferenceId: number, file: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/files/`;
      const data = this._prepareConferenceFile(file);
      return await RequestService.postFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateConferenceFile: async function (conferenceId: number, file: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/files/${file.id}/`;
      const data = this._prepareConferenceFile(file);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteConferenceFile: async function (conferenceId: number, fileId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/files/${fileId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // Users
  getConferenceUsers: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/users/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  createConferenceUser: async function (conferenceId: number, user: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/users/`;
      return await RequestService.post(path, user);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateConferenceUser: async function (conferenceId: number, user: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/users/${user.id}/`;
      const data = this._prepareConferenceUser(user);
      return await RequestService.putFormData(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteConferenceUser: async function (conferenceId: number, userId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/users/${userId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  _prepareConferenceUser(user: any) {
    const data = new FormData();
    data.append("first_name", user.first_name);
    data.append("last_name", user.last_name);
    data.append("email", user.email);
    data.append("role", user.role);
    return data;
  },

  // Analytics
  getConferenceAnalytics: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardExportOptions: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/export-options`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  downloadDashboardExport: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/export`;
      return await RequestService.getBlob(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardStages: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/stages`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardStage: async function (conferenceId: number, stageId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/stages/${stageId}/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardShowrooms: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/showrooms`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardShowroom: async function (conferenceId: number, showroomId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/showrooms/${showroomId}/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardSessions: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/sessions`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardSession: async function (conferenceId: number, sessionId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/sessions/${sessionId}/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardMeetings: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/meetings`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getDashboardAttendees: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/dashboard/attendees`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default ManagementService;
