import Vue from "vue";
import RequestService from "@/services/request.service";
import { AppError } from "@/services/error.service";
import AttendeeModel from "@/models/chat-attendee.model";
import AttendeeGroupModel from "@/models/attendee-group.model";
import TokenService from "../token.service";

const AttendeeService = {
  // -------------------------------- Attendee --------------------------------
  // search Filters the results by attendee name
  // group Filters the results by group ID
  getAttendees: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getAttendee: async function (conferenceId: number, attendeeId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/${attendeeId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getNewChatAttendees: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/conferences/${conferenceId}/attendees/quick-search/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  createAttendee: async function (conferenceId: number, attendee: AttendeeModel) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/`;
      const data = Vue.prototype.copy(attendee);
      data.group = data.group.id;
      return await RequestService.post(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateAttendee: async function (conferenceId: number, attendee: AttendeeModel) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/${attendee.id}/`;
      const data = Vue.prototype.copy(attendee);
      data.group = data.group.id;
      return await RequestService.put(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteAttendee: async function (conferenceId: number, attendeeId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/${attendeeId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // file_format Select your file format. Valid values are: csv, xlsx, JSON, tsv.
  // search Filters the results by attendee name
  // group Filters the results by group ID
  exportAttendees: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/export/`;
      return await RequestService.getBlob(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  importAttendees: async function (conferenceId: number, file: File) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/import/`;
      const headers = {
        Authorization: `Bearer ${TokenService.getAccessToken()}`,
        "Content-Type": "multipart/form-data",
      };
      const formData = new FormData();
      formData.append("file", file);
      return await RequestService.postFormData(path, formData, headers);
    } catch (error) {
      throw new AppError(error);
    }
  },

  disableAttendeeChat: async function (conferenceId: number, attendeeId: number, isDisabled: boolean) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/${attendeeId}/`;
      const data = {
        disable_public_chat: isDisabled,
      };
      return await RequestService.patch(path, data);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // -------------------------------- Set invitation emails --------------------------------
  sendInvitations: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/send-invites/`;
      return await RequestService.post(path, null, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  sendInvitation: async function (conferenceId: number, attendeeId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendees/${attendeeId}/send-invite/`;
      return await RequestService.post(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  // -------------------------------- Groups --------------------------------
  getGroups: async function (conferenceId: number, filters: any) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendee-groups/`;
      return await RequestService.get(path, filters);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getGroup: async function (conferenceId: number, groupId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendee-groups/${groupId}/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  getGroupOptions: async function (conferenceId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendee-groups/options/`;
      return await RequestService.get(path);
    } catch (error) {
      throw new AppError(error);
    }
  },

  createGroup: async function (conferenceId: number, group: AttendeeGroupModel) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendee-groups/`;
      return await RequestService.post(path, group);
    } catch (error) {
      throw new AppError(error);
    }
  },

  updateGroup: async function (conferenceId: number, group: AttendeeGroupModel) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendee-groups/${group.id}/`;
      return await RequestService.put(path, group);
    } catch (error) {
      throw new AppError(error);
    }
  },

  deleteGroup: async function (conferenceId: number, groupId: number) {
    try {
      const path = `/api/v1/management/conferences/${conferenceId}/attendee-groups/${groupId}/`;
      return await RequestService.delete(path);
    } catch (error) {
      throw new AppError(error);
    }
  },
};

export default AttendeeService;
