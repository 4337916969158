import AttendeeGroupModel from "@/models/attendee-group.model";

export default class AttendeeModel {
  id: number;
  first_name: string;
  last_name: string;
  avatar?: string;
  email: string;
  registered: boolean;
  registered_date?: Date;
  created: Date;
  invitation_email_sent: boolean;
  invitation_email_date?: Date;
  invitation_key?: string;
  group: AttendeeGroupModel;
  public_profile: boolean;

  in_active_call: boolean;

  constructor(data: Record<string, any>) {
    this.id = data.id;
    this.avatar = data.avatar;
    this.email = data.email;
    this.first_name = data.first_name || "Annon";
    this.last_name = data.last_name || "";
    this.registered = data.registered || false;
    this.registered_date = data.registered_date;
    this.created = data.created;
    this.invitation_email_date = data.invitation_email_date;
    this.invitation_email_sent = data.invitation_email_sent;
    this.invitation_key = data.invitation_key;
    this.group = new AttendeeGroupModel(data.group);
    this.public_profile = data.public_profile;
    this.in_active_call = false;
  }

  // constructor(id: number, first_name: string, last_name: string, avatar: string, registered: boolean, registered_date: Date, created: Date, group: AttendeeGroupModel) {
  //   this.id = id;
  //   this.avatar = avatar;
  //   this.first_name = first_name || "Annon";
  //   this.last_name = last_name || "Annon";
  //   this.registered = registered;
  //   this.registered_date = registered_date;
  //   this.created = created;
  //   this.group = group;
  // }
}
