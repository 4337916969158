import Router from "@/router";
import Vue from "vue";
import StorageService from "./storage.service";
import axios from "axios";

const CommonService = {
  // // translations

  yesnoOptions() {
    return [
      { key: "true", value: Vue.prototype.$gettext("Yes") },
      { key: "false", value: Vue.prototype.$gettext("No") },
    ];
  },

  routerTitles() {
    return {
      // Public
      login: Vue.prototype.$gettext("Login"),
      invitation: Vue.prototype.$gettext("Invitation"),
      error: Vue.prototype.$gettext("Error"),
      locked: Vue.prototype.$gettext("Locked"),
      "404": Vue.prototype.$gettext("Not found"),
      "password-forgot": Vue.prototype.$gettext("Forgot password"),
      "password-reset": Vue.prototype.$gettext("Password reset"),
      // Common
      loading: Vue.prototype.$gettext("Loading"),
      app: Vue.prototype.$gettext("App"),
      "app.conferences": Vue.prototype.$gettext("Conferences"),
      "app.conference": Vue.prototype.$gettext("Conference"),
      "app.conference.lobby": Vue.prototype.$gettext("Lobby"),
      "app.conference.stages": Vue.prototype.$gettext("Stages"),
      "app.conference.stage": Vue.prototype.$gettext("Stage"),
      "app.conference.showrooms": Vue.prototype.$gettext("Showrooms"),
      "app.conference.showroom": Vue.prototype.$gettext("Showroom"),
      "app.conference.sessions": Vue.prototype.$gettext("Sessions"),
      "app.conference.session": Vue.prototype.$gettext("Session"),
      "app.conference.meetings": Vue.prototype.$gettext("Meetings"),
      "app.conference.meeting": Vue.prototype.$gettext("Meeting"),
      "app.conference.attendees": Vue.prototype.$gettext("Attendees"),
      "app.conference.attendee": Vue.prototype.$gettext("Attendee"),
      // Management
      management: Vue.prototype.$gettext("Management"),
      "management.conferences": Vue.prototype.$gettext("Conferences"),
      "management.conference": Vue.prototype.$gettext("Conference"),
      "management.conference.analytics": Vue.prototype.$gettext("Analytics"),
      "management.conference.stages": Vue.prototype.$gettext("Stages"),
      "management.conference.showrooms": Vue.prototype.$gettext("Showrooms"),
      "management.conference.showroom": Vue.prototype.$gettext("Showroom"),
      "management.conference.sessions": Vue.prototype.$gettext("Sessions"),
      "management.conference.session": Vue.prototype.$gettext("Session"),
      "management.conference.attendees": Vue.prototype.$gettext("Attendees"),
      "management.conference.groups": Vue.prototype.$gettext("Groups"),
      "management.conference.announcements": Vue.prototype.$gettext("Announcements"),
      "management.conference.stage": Vue.prototype.$gettext("Stage dashboard"),
      "management.conference.email-template": Vue.prototype.$gettext("Email template"),
      "management.conference.settings": Vue.prototype.$gettext("Settings"),
    };
  },

  socketState() {
    return {
      CONNECTING: {
        id: 0,
        name: "CONNECTING",
        class: "connecting",
        text: Vue.prototype.$gettext("Connecting"),
        description: Vue.prototype.$gettext("Socket has been created. The connection is not yet open."),
      },
      OPEN: {
        id: 1,
        name: "OPEN",
        class: "open",
        text: Vue.prototype.$gettext("Open"),
        description: Vue.prototype.$gettext("The connection is open and ready to communicate."),
      },
      CLOSING: {
        id: 2,
        name: "CLOSING",
        class: "closing",
        text: Vue.prototype.$gettext("Closing"),
        description: Vue.prototype.$gettext("The connection is in the process of closing."),
      },
      CLOSED: {
        id: 3,
        name: "CLOSED",
        class: "closed",
        text: Vue.prototype.$gettext("Closed"),
        description: Vue.prototype.$gettext("The connection is closed or couldn't be opened."),
      },
      RETRYING: {
        id: 4,
        name: "RETRYING",
        class: "retrying",
        text: Vue.prototype.$gettext("Retrying"),
        description: Vue.prototype.$gettext("The connection is closed but its retrying."),
      },
      UNKNOWN: {
        id: 5,
        name: "UNKNOWN",
        class: "unknown",
        text: Vue.prototype.$gettext("Unknown"),
        description: Vue.prototype.$gettext("Something is wrong with the socket connection"),
      },
    };
  },

  emailSentStatus() {
    return {
      ACTIVE: {
        class: "badge-primary",
        name: Vue.prototype.$gettext("Active"),
        desc: Vue.prototype.$gettext("This is the active email template."),
      },
      NOT_SENT: {
        class: "badge",
        name: Vue.prototype.$gettext("Not sent"),
        desc: Vue.prototype.$gettext("The emails have not been sent."),
      },
      SENT: {
        class: "badge-success",
        name: Vue.prototype.$gettext("Sent"),
        desc: Vue.prototype.$gettext("The emails have been sent."),
      },
      SENDING: {
        class: "badge-success",
        name: Vue.prototype.$gettext("Sending"),
        desc: Vue.prototype.$gettext("The emails are being sent."),
      },
      SCHEDULED: {
        class: "starting",
        name: Vue.prototype.$gettext("Scheduled"),
        desc: Vue.prototype.$gettext("The emails are scheduled to be sent."),
      },
      FAILED: {
        class: "badge-danger",
        name: Vue.prototype.$gettext("Failed"),
        desc: Vue.prototype.$gettext("The emails have failed to send."),
      },
    };
  },

  qaQuestionStateStatus() {
    return {
      OPEN: {
        class: "open",
        name: Vue.prototype.$gettext("Open"),
        desc: Vue.prototype.$gettext("The question is open and can be answered."),
      },
      ANSWERED: {
        class: "answered",
        name: Vue.prototype.$gettext("Answered"),
        desc: Vue.prototype.$gettext("The question has been answered."),
      },
      JUST_ANSWERED: {
        class: "answered",
        name: Vue.prototype.$gettext("Just answered"),
        desc: Vue.prototype.$gettext("The question has just been answered."),
      },
      DISMISSED: {
        class: "dismissed",
        name: Vue.prototype.$gettext("Dismissed"),
        desc: Vue.prototype.$gettext("The question has been dismissed."),
      },
    };
  },

  stageStatus() {
    return {
      live: {
        class: "live",
        name: Vue.prototype.$gettext("Live"),
        desc: Vue.prototype.$gettext("The stage is live!"),
      },
      starting: {
        class: "starting",
        name: Vue.prototype.$gettext("Starting"),
        desc: Vue.prototype.$gettext("The stage is starting soon."),
      },
      ended: {
        class: "ended",
        name: Vue.prototype.$gettext("Ended"),
        desc: Vue.prototype.$gettext("The stage has ended."),
      },
      next: {
        class: "next",
        name: Vue.prototype.$gettext("Next"),
        desc: Vue.prototype.$gettext("The stage is next up."),
      },
    };
  },

  conferenceStatus() {
    return {
      scheduled: {
        class: "scheduled",
        name: Vue.prototype.$gettext("Scheduled"),
        desc: Vue.prototype.$gettext("The conference's start date is in the future."),
      },
      canceled: {
        class: "canceled",
        name: Vue.prototype.$gettext("Canceled"),
        desc: Vue.prototype.$gettext("The conference has been cancelled."),
      },
      inProgress: {
        class: "in-progress",
        name: Vue.prototype.$gettext("In progress"),
        desc: Vue.prototype.$gettext("The conference has begun, but is not yet completed."),
      },
      onHold: {
        class: "on-hold",
        name: Vue.prototype.$gettext("On hold"),
        desc: Vue.prototype.$gettext("The conference has been placed on hold before it began."),
      },
      stopped: {
        class: "stopped",
        name: Vue.prototype.$gettext("Stopped"),
        desc: Vue.prototype.$gettext("The conference began but was stopped before completion."),
      },
      completed: {
        class: "completed",
        name: Vue.prototype.$gettext("Completed"),
        desc: Vue.prototype.$gettext("The conference has completed."),
      },
    };
  },

  ctaTypes() {
    return {
      NO_ACTION: { name: Vue.prototype.$gettext("No action") },
      ALL_STAGES: { name: Vue.prototype.$gettext("All stages") },
      ALL_SHOWROOMS: { name: Vue.prototype.$gettext("All showrooms") },
      ALL_SESSIONS: { name: Vue.prototype.$gettext("All sessions") },
      STAGE: { name: Vue.prototype.$gettext("Stage") },
      SHOWROOM: { name: Vue.prototype.$gettext("Showroom") },
      SESSION: { name: Vue.prototype.$gettext("Session") },
      CUSTOM: { name: Vue.prototype.$gettext("Custom") },
    };
  },

  ctaTypesArray() {
    return CommonService.mapToArray(CommonService.ctaTypes());
  },

  notificationActionTypes() {
    return {
      Chat: {
        name: "app.conference.meeting",
        paramName: "meetingId",
      },
      Stage: {
        name: "app.conference.stage",
        paramName: "stageId",
      },
      Session: {
        name: "app.conference.session",
        paramName: "sessionId",
      },
      Showroom: {
        name: "app.conference.showroom",
        paramName: "showroomId",
      },
      Conference: {
        name: "app.conference.lobby",
        paramName: "conferenceId",
      },
      STAGE: {
        name: "app.conference.stage",
        paramName: "stageId",
      },
      SESSION: {
        name: "app.conference.session",
        paramName: "sessionId",
      },
      SHOWROOM: {
        name: "app.conference.showroom",
        paramName: "showroomId",
      },
      ALL_STAGES: {
        name: "app.conference.stages",
      },
      ALL_SHOWROOMS: {
        name: "app.conference.showrooms",
      },
      ALL_SESSIONS: {
        name: "app.conference.sessions",
      },
    };
  },

  industryTypes() {
    return [
      { key: "Accounting", value: Vue.prototype.$gettext("Accounting") },
      { key: "Administration & Office Support", value: Vue.prototype.$gettext("Administration & Office Support") },
      { key: "Advertising, Arts & Media", value: Vue.prototype.$gettext("Advertising, Arts & Media") },
      { key: "Banking & Financial Services", value: Vue.prototype.$gettext("Banking & Financial Services") },
      { key: "Call Centre & Customer Service", value: Vue.prototype.$gettext("Call Centre & Customer Service") },
      { key: "Community Services & Development", value: Vue.prototype.$gettext("Community Services & Development") },
      { key: "Construction", value: Vue.prototype.$gettext("Construction") },
      { key: "Consulting & Strategy", value: Vue.prototype.$gettext("Consulting & Strategy") },
      { key: "Design & Architechture", value: Vue.prototype.$gettext("Design & Architechture") },
      { key: "Education & Training", value: Vue.prototype.$gettext("Education & Training") },
      { key: "Engineering", value: Vue.prototype.$gettext("Engineering") },
      { key: "Farming, Animals & Conservation", value: Vue.prototype.$gettext("Farming, Animals & Conservation") },
      { key: "Government & Defence", value: Vue.prototype.$gettext("Government & Defence") },
      { key: "Healthcare & Medical", value: Vue.prototype.$gettext("Healthcare & Medical") },
      { key: "Hospitality & Tourism", value: Vue.prototype.$gettext("Hospitality & Tourism") },
      { key: "Human Resources & Recruitment", value: Vue.prototype.$gettext("Human Resources & Recruitment") },
      { key: "Information & Communication Technology", value: Vue.prototype.$gettext("Information & Communication Technology") },
      { key: "Insurance & Superannuation", value: Vue.prototype.$gettext("Insurance & Superannuation") },
      { key: "Legal", value: Vue.prototype.$gettext("Legal") },
      { key: "Manufacturing, Transport & Logistics", value: Vue.prototype.$gettext("Manufacturing, Transport & Logistics") },
      { key: "Marketing & Communications", value: Vue.prototype.$gettext("Marketing & Communications") },
      { key: "Mining, Resources & Energy", value: Vue.prototype.$gettext("Mining, Resources & Energy") },
      { key: "Real Estate & Property", value: Vue.prototype.$gettext("Real Estate & Property") },
      { key: "Retail & Consumer Products", value: Vue.prototype.$gettext("Retail & Consumer Products") },
      { key: "Sales", value: Vue.prototype.$gettext("Sales") },
      { key: "Science & Technology", value: Vue.prototype.$gettext("Science & Technology") },
      { key: "Sport & Recreation", value: Vue.prototype.$gettext("Sport & Recreation") },
      { key: "Trades & Services", value: Vue.prototype.$gettext("Trades & Services") },
      { key: "Other", value: Vue.prototype.$gettext("Other") },
    ];
  },

  conferenceUsersTypes() {
    return [
      { key: "OWNER", value: Vue.prototype.$gettext("Owner") },
      { key: "MODERATOR", value: Vue.prototype.$gettext("Moderator") },
    ];
  },

  // // actions

  currentDate(clockDelta: number) {
    return new Date(Date.now() + clockDelta);
  },

  diff(date1: Date, date2: Date) {
    return date1.getTime() - date2.getTime();
  },

  isAfter(date1: Date, date2: Date = new Date()): boolean {
    const dateDifference = this.diff(date2, date1);
    return dateDifference > 0;
  },

  isBefore(date1: Date, date2: Date = new Date()): boolean {
    const dateDifference = this.diff(date1, date2);
    return dateDifference > 0;
  },

  isBetween(startDate: Date, endDate: Date, date: Date = new Date()): boolean {
    return date.getTime() >= startDate.getTime() && date.getTime() <= endDate.getTime();
  },

  mapToArray(obj: Record<string, any>) {
    const arr: Array<Record<string, any>> = [];
    for (const key in obj) {
      arr.push({
        id: key,
        ...obj[key],
      });
    }
    return arr;
  },

  getURLParameters(obj: Record<string, any>) {
    for (const key in obj) {
      obj[key] = Router.currentRoute.query[key];
    }
    return obj;
  },

  editQuery(name: string, value: string) {
    const query = Router.currentRoute.query;
    query[name] = value;
    return { query };
  },

  clearQuery() {
    Router.replace({ query: {} });
  },

  downloadFile(data: any, name: string, type: string, addTimestamp = true) {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");

    let fileName = name;
    if (addTimestamp) {
      const date = new Date();
      const timestamp =
        date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear() + "-" + date.getHours() + "." + date.getMinutes();
      fileName = name + "-" + timestamp + "." + type;
    } else {
      fileName = name + "." + type;
    }
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  },

  async downloadUrl(url: string, name: string, type: string) {
    const response = await axios.get(url, { responseType: "blob" });
    this.downloadFile(response.data, name, type, false);
  },

  setTitle(text: string) {
    // const routeTitle = Router.currentRoute.meta.title
    // document.title = `${text} • ${routeTitle} • ${process.env.VUE_APP_TITLE}`
    document.title = `${text} • ${process.env.VUE_APP_TITLE}`;
  },

  LANGUAGE_KEY: `${process.env.VUE_APP_NAME}_language`,
  saveLanguage(language: string) {
    StorageService.save(CommonService.LANGUAGE_KEY, language);
    Vue.prototype.$events.$language.current = language;
  },

  loadLanguageFromConference(conference: any) {
    if (StorageService.get(CommonService.LANGUAGE_KEY)) {
      return;
    }
    let language = conference.language;
    if (!language) {
      language = "en_US";
    }
    Vue.prototype.$events.$language.current = language;
    return language;
  },

  loadLanguage() {
    let language = StorageService.get(CommonService.LANGUAGE_KEY);
    if (!language) {
      language = "en_US";
    }
    Vue.prototype.$events.$language.current = language;
    return language;
  },

  lightenDarkenColor(colorCode: string, amount: number) {
    let usePound = false;
    if (colorCode[0] == "#") {
      colorCode = colorCode.slice(1);
      usePound = true;
    }

    const num = parseInt(colorCode, 16);
    let r = (num >> 16) + amount;
    if (r > 255) {
      r = 255;
    } else if (r < 0) {
      r = 0;
    }

    let b = ((num >> 8) & 0x00ff) + amount;
    if (b > 255) {
      b = 255;
    } else if (b < 0) {
      b = 0;
    }

    let g = (num & 0x0000ff) + amount;
    if (g > 255) {
      g = 255;
    } else if (g < 0) {
      g = 0;
    }

    let newColor = (g | (b << 8) | (r << 16)).toString(16);
    newColor = newColor.padStart(6, "0");

    return (usePound ? "#" : "") + newColor;
  },

  combineMeetingName(participants, myself) {
    const filteredParticipants = participants.filter((x) => x.id != myself?.id);
    if (filteredParticipants.length == 0) {
      return "Meeting";
    } else if (filteredParticipants.length == 1) {
      return CommonService.combineAttendeeName(filteredParticipants[0]);
    } else {
      return filteredParticipants.map((x) => x.first_name).join(", ");
    }
  },

  combineAttendeeName(attendee) {
    return (attendee.first_name + " " + attendee.last_name).trim();
  },
};

export default CommonService;
